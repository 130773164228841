import Card from 'react-bootstrap/Card';
import { Col, Container, Row } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import StackedProgressBar from './ProgressBar';
import PieChartWrapper from './PieChartWrapper';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WordCloudChart from './TopicWordCloud';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config.json';
import Image from 'react-bootstrap/Image';


export default function UserReports({ videoLink }) {
    console.log("UserReports component called with videoLink: ", videoLink)
    const [data, setData] = useState(null);
    const [localIdeas, setLocalIdeas] = useState([]);
    const [localSuggestions, setLocalSuggestions] = useState([]);
    const [positive_percentage, setPositivePercentage] = useState(0);
    const [negative_percentage, setNegativePercentage] = useState(0);
    const [neutral_percentage, setNeutralPercentage] = useState(0);
    const [total_comments, setTotalComments] = useState("total comments");
    const [total_analysed, setTotalAnalysed] = useState(0);
    const [doneAnalysing, setDoneAnalysing] = useState(false);
    const [apiCallInProgress, setApiCallInProgress] = useState(false);
    const [callCount, setCallCount] = useState(0);
    const [showInfoBannerMessage, setShowInfoBannerMessage] = useState('');
    const [showErrorBannerMessage, setShowErrorBannerMessage] = useState('');
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('userData')) || {});
    const [newTopicName, setNewTopicName] = useState('');
    const [newTopicResult, setNewTopicResult] = useState('');
    // const { user } = useContext(UserContext);

    const handleNewTopicClick = () => {
        console.log("New topic asked: ", newTopicName)
        const newTopicData = GetNewTopicData(newTopicName);
        setNewTopicResult(newTopicData);
    }

    const GetNewTopicData = (topicName) => {
        const mock_new_topic_data = {
            "topic_name": topicName,
            "comment_count": 0,
            "positive_count": 70,
            "negative_count": 20,
            "neutral_count": 10
        }
        return mock_new_topic_data
    }



    const mock_data = {
        "top_topics": [
            {
                "topic_name": "Malaikotte Valiban movie",
                "comment_count": 200,
                "positive_count": 120,
                "negative_count": 30,
                "neutral_count": 50,
                "summary": "People are discussing the movie 'Malaikotte Valiban' and expressing positive opinions about it."
            },
            {
                "topic_name": "Ezhimala song of Vaaliban",
                "comment_count": 100,
                "positive_count": 10,
                "negative_count": 90,
                "neutral_count": 0,
                "summary": "Viewers are mentioning the song 'Ezhimala' from the movie 'Vaaliban' and appreciating it."
            },
            {
                "topic_name": "Bela Shinde's unique voice",
                "comment_count": 200,
                "positive_count": 100,
                "negative_count": 50,
                "neutral_count": 50,
                "summary": "People are talking about singer Bela Shinde's unique voice and praising it."
            },
            {
                "topic_name": "Dance skills and abilities",
                "comment_count": 90,
                "positive_count": 30,
                "negative_count": 30,
                "neutral_count": 30,
                "summary": "There is a negative comment about someone's dance skills, but it is not sensibly mentioned."
            },
            {
                "topic_name": "Appreciation for the movie",
                "comment_count": 1,
                "positive_count": 1,
                "negative_count": 0,
                "neutral_count": 0,
                "summary": "A viewer who is not Marathi is expressing positive opinions about the movie."
            }
        ],
        "total_comments": 1000,
        "ideas": [
            "Top 10 Romantic Songs of 2021",
            "The Making of Malaikotte Valiban",
            "Interview with Bela Shinde",
            "Best Dance Choreographies in Indian Cinema",
            "Exploring Regional Films: A Journey into Marathi Cinema"
        ],
        "suggestion": [
            "Improve audio quality in videos",
            "Improve audio quality in videos",
            "Improve audio quality in videos"
        ],
        "positive_comments_count": 600,
        "negative_comments_count": 100,
        "neutral_comments_count": 200
    }

    const extract_number = (data) => {
        if (typeof data === 'number') {
            return data;
        } else if (typeof data === 'string') {
            // Try parsing the string to a number
            const parsedNumber = parseFloat(data);
            // Check if the parsed number is not NaN
            if (!isNaN(parsedNumber)) {
                return parsedNumber;
            }
        } else if (typeof data === 'object' && data !== null) {
            // Check if data is an object and not null
            for (const key in data) {
                // Check if the value is a number or a number in a string
                if (typeof data[key] === 'number') {
                    return data[key];
                } else if (typeof data[key] === 'string') {
                    const parsedNumber = parseFloat(data[key]);
                    if (!isNaN(parsedNumber)) {
                        return parsedNumber;
                    }
                }
            }
        }

        // Return null if no number is found
        return null;
    }

    const extract_list = (data) => {
        if (Array.isArray(data)) {
            return data.filter(item => typeof item === 'string');
        } else if (typeof data === 'object' && data !== null) {
            for (const key in data) {
                const result = extract_list(data[key]);
                if (result !== null) {
                    return result;
                }
            }
        }
        return null;
    };

    function expired_login_session(storedDateTime) {
        try {
            const storedDate = new Date(storedDateTime);

            const currentDate = new Date();
            // Set expiry date time to 3 hours after the stored date time
            const expiryDate = new Date(storedDate.getTime() + 3 * 60 * 60 * 1000)

            if (expiryDate.getTime() < currentDate.getTime()) {
                return true;
            }

            return false;
        } catch (error) {
            return true;
        }
    }

    const fetchData = async () => {
        console.log("**************** call count ****************", callCount)
        if (apiCallInProgress) {
            console.log("API call already in progress...")
            return;
        }

        if (callCount > 3) {
            console.log("Called more thatn 3 times already.")
            return;
        }

        setCallCount(callCount + 1)
        setApiCallInProgress(true);


        const localUserData = JSON.parse(localStorage.getItem('userData'))
        console.log("Local user data: ", localUserData)
        setUserData(localUserData)
        const user_email = localUserData.email
        const user_id = localUserData.user_id
        const storedAt = localUserData.storedAt
        if (expired_login_session(storedAt)) {
            localStorage.removeItem('userData')
            window.location.href = "/";
        }

        const input_data = {
            "app-id": "1ecead0c-d3e1-4bc8-b5a3-757b94f2012f",
            "api-key": "228031c9-a95d-40b2-TempSwift1dot-df659df60e86",
            "video_id": videoLink,
            "email": user_email,
            "user_id": user_id,
            "auth_token": localUserData.token,
            "storedAt": storedAt
        };

        function getCommsAPIURL() {
            if (config.env === "live") {
                console.log("Using live API")
                return "https://comms-api-1.azurewebsites.net/api/comms_ai_api"
            }
            else if (config.env === "test") {
                console.log("Using AWS test API")
                return "https://lambodhi.pythonanywhere.com/toptopicsdata"
            }
            else {
                console.log("Using local API")
                return "http://localhost:7071/api/comms_ai_api";
            }
        }

        axios.post(getCommsAPIURL(), input_data, {
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                console.log("Response from API")
                console.log(response.data)
                let responseData = response.data
                while (responseData.hasOwnProperty('data')) {
                    responseData = responseData.data
                }

                setData(responseData);
                setDoneAnalysing(true);
                setApiCallInProgress(false);
            })
            .catch(error => {
                console.error('Error fetching data from API:', error);
                setApiCallInProgress(false);
                setDoneAnalysing(true);
                setShowErrorBannerMessage("Error fetching data from API. Please try again later.");

                const removeError = setTimeout(() => {
                    setShowErrorBannerMessage('')
                }, 4000);


                return removeError
            });
    };

    useEffect(() => {
        console.log("Dashboard initial code. ")
        if (videoLink && !apiCallInProgress) {
            console.log("Fetching data from API...")
            if (config.mock_data_on_client) {
                setData(mock_data);
            }
            else {
                fetchData();
            }
        }
        else {
            console.log("Video link not available or API call in progress.", { videoLink, apiCallInProgress })
        }
    }, [videoLink]);

    useEffect(() => {
        if (apiCallInProgress) {
            setShowInfoBannerMessage("Initializing analysis ...");
            const messages = [
                "Scanning comments for patterns ...",
                "Extracting key insights ...",
                "Exploring comment trends ...",
                "Identifying common themes ...",
                "Calculating statistical metrics ...",
                "Analyzing sentiment ...",
                "Detecting sentiment patterns ...",
                "Investigating user feedback ...",
                "Assessing audience sentiment ...",
                "Parsing comment metadata ...",
                "Examining comment engagement ...",
                "Assessing comment relevance ...",
                "Reviewing comment sentiment ...",
                "Processing comment timestamps ...",
                "Summarizing comment sentiment ...",
                "Evaluating comment sentiment ...",
                "Parsing comment sentiment data ...",
                "Compiling sentiment analysis ...",
                "Analyzing sentiment trends ...",
                "Reviewing comment sentiment patterns ...",
                "Analyzing comment sentiment trends ...",
                "Interpreting comment sentiment data ...",
                "Assessing comment sentiment patterns ...",
                "Identifying sentiment trends ...",
                "Reviewing comment engagement metrics ...",
                "Assessing comment engagement patterns ...",
                "Analyzing comment engagement data ...",
                "Examining comment engagement metrics ...",
                "Summarizing comment engagement trends ...",
                "Mapping data ...",
                "Handing data to UI component ...",
                "Rendering data in UI component ...",
                "Fetching second batch, this should take couple more seconds ...",
                "Starting next batch ...",
            ];

            let currentIndex = 0;
            const interval = setInterval(() => {
                setShowInfoBannerMessage(messages[currentIndex]);
                currentIndex++;

                // Reset index if all messages have been displayed
                if (currentIndex === messages.length) {
                    currentIndex = 0;
                }
            }, 3000);

            // Clear interval when component unmounts or when apiCallInProgress becomes false
            return () => clearInterval(interval);
        }
        else {
            setShowInfoBannerMessage('');
        }
    }, [apiCallInProgress]);

    useEffect(() => {
        if (data) {
            mapCommsData();
        }

        function mapCommsData() {
            console.log("Mapping data from API response ...")
            console.log("Data: ", data);
            data.positive_comments_count = extract_number(data.positive_comments_count);
            data.negative_comments_count = extract_number(data.negative_comments_count);
            data.neutral_comments_count = extract_number(data.neutral_comments_count);
            setLocalIdeas(extract_list(data.ideas));
            setLocalSuggestions(extract_list(data.suggestion));
            const total_analysed = data.positive_comments_count + data.negative_comments_count + data.neutral_comments_count;
            console.log("total_analysed");
            const positive_percentage = Math.ceil((data.positive_comments_count / total_analysed) * 100);
            const negative_percentage = Math.ceil((data.negative_comments_count / total_analysed) * 100);
            const neutral_percentage = Math.ceil((data.neutral_comments_count / total_analysed) * 100);
            console.log(positive_percentage);
            console.log(negative_percentage);
            console.log(neutral_percentage);
            setPositivePercentage(positive_percentage);
            setNegativePercentage(negative_percentage);
            setNeutralPercentage(neutral_percentage);
            setTotalAnalysed(total_analysed);
            setDoneAnalysing(data.analysing === "done");
        }
    }, [data]);

    const TileCard = ({ title, total, positives, negatives, neutrals }) => {
        return (
            <Card className='smallcard' style={{ borderRadius: '30px', borderTop: 'none', borderLeft: 'none', borderRight: 'none', background: '#fffff' }}>
                <Card.Body>
                    <Container>
                        <Row>
                            <Col xs={2} style={{ padding: '1px' }}>
                                <Card.Text title="Total" style={{ width: '75px', padding: '5px', lineHeight: '1', background: 'rgb(244, 247, 254)', borderRadius: '10px', margin: '0px', color: 'blue', fontWeight: 'bold', fontSize: '18px' }} className='smallcardvalue'>{total}<div className='normalText'>comments</div></Card.Text>
                            </Col>

                            <Col xs={10} style={{ padding: '2px', paddingLeft: '30px', textAlign: 'left' }}>
                                <Card.Title className='smallcardlabel'>{title}</Card.Title>

                            </Col>
                        </Row>
                        <Row style={{ marginTop: '2%' }}>
                            <StackedProgressBar total={total} positive={positives} negative={negatives} neutral={neutrals} />
                        </Row>
                    </Container>
                </Card.Body>

            </Card>
        );
    };


    return (
        <div>
            {
                <div className='analysing-text'>
                    {!apiCallInProgress && "Analysed " + total_analysed}
                    {!apiCallInProgress && " / " + total_comments}
                </div>
            }
            {apiCallInProgress && (<div><div className='glowing-border' ></div></div>)}
            {/* {apiCallInProgress && showInfoBannerMessage !== '' && (<div className="info-banner-message">{showInfoBannerMessage}</div>)} */}
            {apiCallInProgress && (<div className="info-banner-message">{showInfoBannerMessage}</div>)}
            {showErrorBannerMessage !== '' && (<div className="red-banner-message">{showErrorBannerMessage}</div>)}

            <div style={{ margin: '10px', marginTop: '40px' }}>
                {data ? (
                    <div>
                        <Container style={{ marginBottom: '30px' }}>
                            <Row xs={3}>
                                {data.top_topics.map((topic, index) => (
                                    <Col style={{ marginBottom: '20px', paddingTop: '10px' }}>
                                        <TileCard title={topic.topic_name} total={topic.comment_count} positives={topic.positive_count} negatives={topic.negative_count} neutrals={topic.neutral_count} />
                                    </Col>
                                ))}
                                <Col style={{ marginBottom: '20px', paddingTop: '10px' }}>
                                    <Card className='smallcard' style={{ borderRadius: '30px', border: 'none', background: '#fffff' }}>
                                        <Card.Body>
                                            <Container>
                                                <Row>
                                                    {newTopicResult === '' && <Col xs={10} style={{ padding: '2px', textAlign: 'left' }}>
                                                        <Card.Title className='smallcardlabel'>➕ New topic</Card.Title>
                                                        <InputGroup style={{ width: '120%' }} className="mb-1">
                                                            <InputGroup.Text id="newtopic-addon">📝</InputGroup.Text>
                                                            <Form.Control
                                                                placeholder="e.g. video content..."
                                                                aria-label="newTopic"
                                                                type='text'
                                                                value={newTopicName}
                                                                aria-describedby="newtopic-addon"
                                                            />
                                                            <button onClick={() => handleNewTopicClick()} className="btn btn-secondary btn-centre">
                                                                🚀
                                                            </button>
                                                        </InputGroup>
                                                    </Col>}
                                                    {newTopicResult !== '' && <Col style={{ marginBottom: '20px', paddingTop: '10px' }}>
                                                        <TileCard title={newTopicResult.topic_name} total={newTopicResult.comment_count} positives={newTopicResult.positive_count} negatives={newTopicResult.negative_count} neutrals={newTopicResult.neutral_count} />
                                                    </Col>}
                                                </Row>

                                            </Container>
                                        </Card.Body>

                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            <Row xs={3}>
                                <Col style={{ borderRadius: '30px', border: 'none', background: '#fffff', textAlign: 'left' }}>
                                    <span className='card-title'>🧐 Overall comments sentiment: </span>
                                    <PieChartWrapper positiveCount={positive_percentage} negativeCount={negative_percentage} neutralCount={neutral_percentage} />
                                </Col>
                                <Col style={{ borderRadius: '30px', border: 'none', background: '#fffff', textAlign: 'left', overflow: 'none' }}>
                                    <span className='card-title'>💡Content Ideas based on comments : </span>

                                    {localIdeas && <Card className='smallcard' style={{ height: '300px', borderRadius: '30px', border: 'none', background: '#fffff' }}>
                                        <Card.Body>
                                            <div>
                                                {localIdeas.map((idea, index) => (
                                                    <div className="btn btn-secondary btn-centre" style={{ border: 'none', color: 'black', background: 'rgb(173, 216, 215)', margin: '2px', borderRadius: '5px' }}>
                                                        🚀 {idea}
                                                    </div>
                                                ))}
                                            </div>
                                        </Card.Body>
                                    </Card>}
                                    {config.topSubCommentors && data.top_commentors && <Card className='smallcard' style={{ height: '180px', borderRadius: '30px', border: 'none', marginTop: '10px', background: '#fffff' }}>
                                        <Card.Body>
                                            👨‍💻 (Coming Soon..) Commentors with most subcribers
                                            {mock_data.top_commentors.map((commentor, index) => (
                                                <div style={{ color: '#B800D8' }}> <AccountCircleIcon /> {commentor.name} ({commentor.subcribers})</div>
                                            ))}
                                        </Card.Body>
                                    </Card>}
                                    {localSuggestions && <Card className='smallcard' style={{ height: '150px', borderRadius: '30px', border: 'none', marginTop: '10px', background: '#fffff' }}>
                                        <Card.Body>
                                            🌱 Suggestion comments
                                            {localSuggestions.map((comment, index) => (
                                                <div style={{ background: 'rgb(247 230 158)', margin: '2px', borderRadius: '5px' }}>
                                                    💭 "{comment}"
                                                </div>
                                            ))}
                                        </Card.Body>
                                    </Card>}
                                    {/* <Card className='smallcard' style={{ height: '150px', borderRadius: '30px', border: 'none', marginTop: '10px', background: '#fffff' }}>
                                <Card.Body>
                                    Average age, top locations
                                </Card.Body>
                            </Card> */}

                                </Col>
                                <Col style={{ borderRadius: '30px', border: 'none', background: '#fffff', textAlign: 'left' }}>
                                    <span className='card-title'>📮Chat bot </span>

                                    <Card className='smallcard' style={{ height: '150px', borderRadius: '30px', border: 'none', marginTop: '10px', background: '#fffff' }}>
                                        <Card.Body>
                                            <div style={{ color: 'rgb(203 81 228)', fontSize: '24px' }} >📣 Coming Soon!</div>
                                            <Image style={{ width: '320px', height: '300px', borderRadius: '60px' }} src={require("../assets/img/messages.png")} />

                                        </Card.Body>
                                    </Card>
                                    {config.mostLiked && data.mostLiked && <Card className='smallcard' style={{ height: '150px', borderRadius: '30px', border: 'none', marginTop: '10px', background: '#fffff' }}>
                                        <Card.Body>
                                            ❤️ (Coming Soon..) Most liked comments
                                            {mock_data.most_liked.map((comment, index) => (
                                                <div style={{ background: 'rgb(245, 210, 247)', margin: '2px', borderRadius: '5px' }}>
                                                    📣 "{comment}"
                                                </div>
                                            ))}
                                        </Card.Body>
                                    </Card>}
                                    {config.allComments && data.all_topics &&
                                        <Card className='smallcard' style={{ height: '150px', borderRadius: '30px', border: 'none', marginTop: '10px', background: '#fffff' }}>
                                            <Card.Body>
                                                📚 All topics (Coming Soon)
                                                <WordCloudChart data={mock_data.all_topics}></WordCloudChart>
                                            </Card.Body>
                                        </Card>}
                                </Col>
                            </Row>
                        </Container>
                    </div>) : (
                    <div></div>
                )}
            </div>
        </div>
    );

}