import * as React from 'react';
import { PieChart } from '@mui/x-charts';

function PieChartWrapper({ positiveCount, negativeCount, neutralCount }) {

    return (
        <PieChart
            series={[
                {
                    data: [
                        { id: 0, value: positiveCount, label: "Total " + positiveCount + " % Positive 😀 Comments", color: "#02B2AF" },
                        { id: 1, value: neutralCount, label: "Total " + neutralCount + " % Neutral 😐 Comments", color: "#2D96FF" },
                        { id: 2, value: negativeCount, label: "Total " + negativeCount + " % Negative 😡 Comments", color: "#B800D8" },
                    ],
                    innerRadius: 30,
                    outerRadius: 100,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    startAngle: -90,
                    endAngle: 180,
                    cx: 150,
                    cy: 150,
                },
            ]}
            width={280}
            height={650}
        />
    )
}

export default PieChartWrapper;