import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

function FancyLogin({ onLogin }) {
  return (
    <GoogleLogin
      onSuccess={credentialResponse => {
        console.log('Fancy Login Success!');
        var credRes = jwtDecode(credentialResponse.credential);
        console.log(credRes);
        const userData = {
          ...credRes,
          storedAt: new Date().getTime()
        };
        localStorage.setItem('userData', JSON.stringify(userData));
        onLogin(credRes)
      }}
      onError={() => {
        console.log('Login Failed');
      }}
      auto_select={true}
    />)
}

export default FancyLogin;