import React, { useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams hook from react-router-dom



const HomeTab = () => {
    const { ideaid } = useParams();
    const [iid, setIid] = useState(ideaid)
    setIid(1)

    return (
        <div>
            {<h4>Compose Video Tab</h4>}
            {iid && <h6>Idea id: {ideaid}</h6>}

        </div >
    );
}

export default HomeTab;
