import React, { useEffect } from 'react';
import config from '../config';


function getCommsAPIURL() {
  if (config.env === "live") {
    console.log("Using live API")
    return "https://comms-api-1.azurewebsites.net/api/stored_comments"
  }
  else if (config.env === "test") {
    console.log("Using AWS test API")
    return "https://lambodhi.pythonanywhere.com/stored_comments"
  }
  else {
    console.log("GET COMMS: Using local API")
    return "http://localhost:7071/api/stored_comments";
  }
}

function getLocallyStoredComments(videoId) {
  let storedComments = JSON.parse(localStorage.getItem('comments')) || {};
  return storedComments[videoId] || [];
}

// Function to store comments for a video in local storage
function storeComments(videoId, comments) {
  // Get existing comments if any
  let storedComments = JSON.parse(localStorage.getItem('comments')) || {};

  // Update or set the comments for the specified video ID
  storedComments[videoId] = comments;

  // Store the updated comments back to local storage
  localStorage.setItem('comments', JSON.stringify(storedComments));
}


const MyComponent = ({ video_id, email, comment_ids, onResult }) => {
  useEffect(() => {
    console.log("DEBUG: Broker fetching comments for video", video_id);
    const fetchData = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          "app_id": "1ecead0c-d3e1-4bc8-b5a3-757b94f2012f",
          "api_key": "228031c9-a95d-40b2-TempSwift1dot-df659df60e86",
          "email": email,
          "video_id": video_id,
          "comment_ids": comment_ids
        });

        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        const response = await fetch(getCommsAPIURL(), requestOptions);
        const result = await response.json();
        console.log("DEBUG json results", result);
        storeComments(video_id, result);
        onResult(result);
      } catch (error) {
        console.log('error', error);
      }
    };

    const comms = getLocallyStoredComments(video_id);
    if (comms && comms.length > 0) {
      console.log("DEBUG: Using locally stored comments");
      onResult(comms);
    }
    else {
      console.log("DEBUG: Calling server for comments.");
      fetchData();
    }
  }, []);

  return (
    <>
    </>
  );
};

export default MyComponent;
