import React, { useState } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { blue, green, purple } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import QueueIcon from '@mui/icons-material/Queue';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import RocketIcon from '@mui/icons-material/Rocket';
import DAstatus from './constants/DeepAnalysisStatusEnum';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';


export default function DeepAnalysis({ currentStatusInput }) {
  const [status, setStatus] = useState(currentStatusInput ? currentStatusInput : DAstatus.NOT_STARTED);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const timer = React.useRef();

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    if (status === DAstatus.NOT_STARTED && !loading) {
      setLoading(true);
      timer.current = setTimeout(() => {
        setStatus(DAstatus.QUEQED);
        setLoading(false);
      }, 2000);
      return
    }
  };

  const get_colour = (status) => {
    if (status === DAstatus.NOT_STARTED) {
      return 'secondary';
    }
    if (status === DAstatus.QUEQED) {
      return 'primary';
    }
    if (status === DAstatus.IN_PROGRESS) {
      return 'warning';
    }
    if (status === DAstatus.DONE) {
      return 'success';
    }
  }

  const get_deep_analysis_description = () => {
    const notStartedDAMessage = 'Deep Analysis has not been started yet.'
    const queuedDAMessage = 'Deep Analysis has been queued for processing.'
    const inProgressDAMessage = 'Deep Analysis is in progress.'
    const doneDAMessage = 'Deep Analysis has been completed.'

    if (status === DAstatus.NOT_STARTED) {
      return notStartedDAMessage;
    }
    if (status === DAstatus.QUEQED) {
      return queuedDAMessage;
    }
    if (status === DAstatus.IN_PROGRESS) {
      return inProgressDAMessage;
    }
    if (status === DAstatus.DONE) {
      return doneDAMessage;
    }

    return '';
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      Deep Analysis is  <Tooltip title={get_deep_analysis_description()}>  <Button style={{ marginTop: '4px', marginLeft: '2px' }} color={get_colour(status)} >{status}</Button>  </Tooltip>
      <Box sx={{ m: 1, position: 'relative' }}>
        <Fab
          aria-label="start deep analysis"
          color={get_colour(status)}
          sx={buttonSx}
          onClick={handleButtonClick}
        >
          {status === DAstatus.NOT_STARTED && <RocketIcon />}
          {status === DAstatus.QUEQED && <HourglassEmptyIcon />}
          {status === DAstatus.DONE && <CheckIcon />}
        </Fab>
        {loading && (
          <CircularProgress
            size={68}
            sx={{
              color: blue[500],
              position: 'absolute',
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}

      </Box>
    </Box>
  );
}
