import ProgressBar from 'react-bootstrap/ProgressBar';

function StackedProgressBar({ total, positive, negative, neutral }) {
  const positive_percentage = (positive / total) * 100
  const negative_percentage = (negative / total) * 100
  const neutral_percentage = (neutral / total) * 100

  return (
    <ProgressBar style={{ padding: '0px' }}>
      <ProgressBar className='themegreen' title="😀 Positive" now={positive_percentage} key={1} />
      <ProgressBar className='themered' title="😡 Negative" now={negative_percentage} key={2} />
      <ProgressBar className='themeblue' title="😐 Neutral" now={neutral_percentage} key={3} />

    </ProgressBar>
  );
}

export default StackedProgressBar;