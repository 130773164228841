import React, { useState, useEffect } from 'react';
import AnalysisDashboard from './AnalysisDashboard';
import VideoList from './VideoList';
import config from '../config';
import IconButton from '@mui/material/IconButton';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import Button from 'react-bootstrap/Button';


const success = 'success';

const Pages = {
    SEARCH: 'search',
    ANALYSIS_DASHBOARD: 'analysis_dashboard'
}

const HomeTab = () => {
    const [results, setResults] = useState('');
    const [analysisSampleData, setAnalysisSampleData] = useState('');
    const [analysingList, setAnalysingList] = useState([]);
    const [analysedDataList, setAnalysedDataList] = useState([]);
    const [tCommsOnVid, setTCommsOnVid] = useState('');
    const [showPage, setShowPage] = useState('search')
    const [searchPage, setSearchPage] = useState('text')
    const [currentDashboardItem, setCurrentDashboardItem] = useState('');
    const [currentDashboardData, setCurrentDashboardData] = useState('');
    const [fetchDataVideoTitle, setFetchDataVideoTitle] = useState('');
    const [fetchDataVideoId, setFetchDataVideoId] = useState('');
    const [retryCount, setRetryCount] = useState(0);

    function onSampleDataReceived(status, searchData, analysisData) {
        console.log('DEBUG: Received search and analysis data', status, searchData, analysisData);
        if (searchPage === '') {
            setSearchPage('text');
        }
        setResults(searchData);
        const analysed_data = analysisData.map((item, idx) => { return { key: item.videoId, data: item } });
        setAnalysedDataList(analysed_data);
        setTCommsOnVid('')
        localStorage.setItem('analysisSampleData1', JSON.stringify(analysisData));
        localStorage.setItem('resultsSampleData1', JSON.stringify(searchData));
    }

    function onActionClick(videoToAnalyse, currentStatus) {
        if (currentStatus === 'analyse') {
            if (analysedDataList.some(itemData => itemData.key === videoToAnalyse.id.videoId)) {
                setCurrentDashboardItem({ id: videoToAnalyse.id.videoId, title: videoToAnalyse.snippet.title, statistics: videoToAnalyse.statistics });
                setCurrentDashboardData(analysedDataList.find(itemData => itemData.key === videoToAnalyse.id.videoId).data);
                setFetchDataVideoTitle(videoToAnalyse.snippet.title);
                setShowPage(Pages.ANALYSIS_DASHBOARD);
                return
            }
            if (!(analysingList.some(item => item === videoToAnalyse.id.videoId))) {
                setAnalysingList([...analysingList, videoToAnalyse.id.videoId]);
                setFetchDataVideoId(videoToAnalyse.id.videoId);
                setFetchDataVideoTitle(videoToAnalyse.snippet.title);
                setTCommsOnVid(videoToAnalyse.statistics.commentCount ? videoToAnalyse.statistics.commentCount : 0)
                return
            }
        }
        if (currentStatus === 'view') {
            setCurrentDashboardItem({ id: videoToAnalyse.id.videoId, title: videoToAnalyse.snippet.title, statistics: videoToAnalyse.statistics });
            setCurrentDashboardData(analysedDataList.find(itemData => itemData.key === videoToAnalyse.id.videoId).data);
            setFetchDataVideoTitle(videoToAnalyse.snippet.title);
            setShowPage(Pages.ANALYSIS_DASHBOARD);
            return
        }
        return
    }

    useEffect(() => {
        // IF current page url is /analysissamples, then fetch data
        const cachedSampleData = localStorage.getItem('analysisSampleData1');
        const cachedResults = localStorage.getItem('resultsSampleData1');
        const cached = cachedSampleData && cachedResults;
        if (cached) {
            onSampleDataReceived(success, JSON.parse(cachedResults), JSON.parse(cachedSampleData));
            return
        }

        if (!cached && retryCount < 4 && showPage === Pages.SEARCH) {
            if (analysisSampleData === '' && results === '') {
                fetchSampleData();
                setRetryCount(retryCount + 1);
            }
        }
    }, []);

    function fetchSampleData() {
        const input_data = {
            "app_id": "1ecead0c-d3e1-4bc8-b5a3-757b94f2012f",
            "api_key": "228031c9-a95d-40b2-TempSwift1dot-df659df60e86",
        };

        function getCommsAPIURL() {
            if (config.env === "live") {
                console.log("Using live API")
                return "https://comms-api-1.azurewebsites.net/api/sample_data"
            }
            else if (config.env === "test") {
                console.log("Using AWS test API")
                return "https://lambodhi.pythonanywhere.com/sample_data"
            }
            else {
                console.log("GET COMMS: Using local API")
                return "http://localhost:7071/api/sample_data";
            }
        }

        const fetchData = async () => {
            try {
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                const raw = JSON.stringify(input_data);

                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                const response = await fetch(getCommsAPIURL(), requestOptions);
                const result = await response.json();
                console.log("DEBUG json results", result);
                onSampleDataReceived(result.status, result.search_results, result.analysis_results);
            } catch (error) {
                console.log('error', error);
            }
        };

        fetchData()

    };

    function onReturnToSearch() {
        setShowPage('search');
    }

    return (
        <div>
            <h6 className='m_container' style={{ marginBottom: "16px", alignItems: "center" }}>Analysis Samples</h6>
            {showPage === Pages.SEARCH &&
                (
                    <div>
                        {searchPage === 'text' && results !== '' &&
                            <> <hr style={{ borderTop: '1px solid #000000' }} />
                                <VideoList results={results} onActionClick={onActionClick} analysingList={analysingList} analysedList={analysedDataList.map(itemData => itemData.key)} />
                            </>
                        }
                    </div>
                )
            }
            {showPage === Pages.ANALYSIS_DASHBOARD &&
                (<div>
                    <AnalysisDashboard videoInfo={currentDashboardItem} analysisData={currentDashboardData} onReturnToSearch={onReturnToSearch} tCommsOnVid={tCommsOnVid} onAnalysisDone={{}} type="initial" />
                </div>)
            }
            <div className='m_container' style={{ marginTop: "20px", marginBottom: "16px", alignItems: "center" }}><Button href="/" style={{ backgroundColor: "#B800D8" }} variant="secondary">
                Analyse videos for free <RocketLaunchIcon />
            </Button></div>
        </div >
    );
}

export default HomeTab;
