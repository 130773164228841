import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import BarChartIcon from '@mui/icons-material/BarChart';
import AnalysisStatus from './AnalysisStatus';
import Chip from '@mui/material/Chip';
import AnalysisLoading from './AnalysisLoading';

const VideoList = ({ results, onActionClick, analysingList, analysedList }) => {

  console.log('VideoList > results: ', results, 'analysingList: ', analysingList, 'analysedList: ', analysedList);

  function onAnalyseClick(itemToAnalyse) {
    console.log('LandingPage > Analyse clicked for: ', itemToAnalyse);
    onActionClick(itemToAnalyse, AnalysisStatus.ANALYSE)
  }

  function onViewClick(itemToView) {
    console.log('LandingPage > View clicked for: ', itemToView);
    onActionClick(itemToView, AnalysisStatus.VIEW)
  }

  function onLoadingClick(itemToLoad) {
    console.log('LandingPage > Loading clicked for: ', itemToLoad);
    onActionClick(itemToLoad, AnalysisStatus.LOADING)
  }

  return (
    <div className='container'>
      <Row xs={1} md={3} className="g-4">
        {results.map((item, idx) => (
          <Col key={idx}>
            <Card>
              {/* 364px x 20.74 for mobile  */}
              <iframe width="100%" height="204.74px" src={"https://www.youtube.com/embed/" + item.id.videoId} title={item.snippet.title} allowFullScreen></iframe>
              <Card.Body>
                <Card.Text>
                  <span dangerouslySetInnerHTML={{ __html: item.snippet.title }}></span>
                  {/* TODO: Add Video likes and number of comments  */}
                </Card.Text>
                <Card.Footer style={{ background: "none" }}>
                  <Chip color="success" variant="outlined" label={(!item.statistics.commentCount ? 0 : item.statistics.commentCount) + " comments"} />
                </Card.Footer>
                <Card.Footer style={{ background: "none" }}>
                  {analysingList.some(stored_item => stored_item === item.id.videoId) && <AnalysisLoading onClick={() => onLoadingClick(item)}></AnalysisLoading>}
                  {!(analysedList.some(stored_item => stored_item === item.id.videoId) || analysingList.some(stored_item => stored_item === item.id.videoId)) &&
                    (<>
                      {item.statistics && item.statistics.commentCount && item.statistics.commentCount !== 0 &&
                        <Button style={{ backgroundColor: "#B800D8" }} onClick={() => onAnalyseClick(item)} variant="secondary">
                          Analyse <RocketLaunchIcon />
                        </Button>}
                    </>
                    )
                  }
                  {analysedList.some(stored_item => stored_item === item.id.videoId) &&
                    <Button onClick={() => onViewClick(item)} variant="success">
                      View Analysis <BarChartIcon />
                    </Button>
                  }
                </Card.Footer>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div >
  )
}

export default VideoList
