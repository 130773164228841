import React, { useState, useEffect } from 'react';
import config from '../config.json';
import InputGroup from 'react-bootstrap/InputGroup';
import { Image, Form } from "react-bootstrap"
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';


function YoutubeSearch({ searchText, onUrlSubmit, onTextSubmit, onSearchResults, onUrlResult, previousResults }) {
  const [searching, setSearching] = useState(true);
  const [query, setQuery] = useState('');
  const [alreadyStored, setAlreadyStored] = useState(false);
  const [showBannerMessage, setShowBannerMessage] = useState('');

  const onClear = () => {
    setQuery('');
    onSearchResults([])
  }

  useEffect(() => {
    console.log("Search box load...");
    if (!previousResults) {
      console.log("Previous results not found...");
      if (searchText) {
        const timer = setTimeout(() => {
          // check if search result exist in my local storage
          const data = JSON.parse(localStorage.getItem(searchText));
          console.log("---------- Local storage search results:", data);

          // if (!data) {
          //   console.log("No results found in local storage, getting from server...");
          //   data = getSearverSearchResults(searchText);
          // }

          if (data) {
            if (data.expiry >= Date.now()) {
              console.log("Search results found in local storage...");
              setSearching(false);
              setAlreadyStored(true)
              onSearchResults(data.value);
              return;
            }

            if (data.expiry < Date.now()) {
              console.log("Expired search result found...");
              localStorage.removeItem('latestHomeSearchResults');
            }
          }

          console.log("Youtube: Searching home query text...");
          setQuery(searchText);
          youtube_api_call(searchKeyword);
          onTextSubmit();
          setSearching(false);
          setQuery('')
        }, 1000); // 2000 milliseconds = 2 seconds

        return () => clearTimeout(timer);
      }
      return
    }
    else {
      console.log("Previous results found...");
      setSearching(false);
    }
  }, []);

  function validSearchText(text) {
    if (!query || query.trim() === '') {
      return false;
    }
    const regex = /^[a-zA-Z0-9\s\-_'.,!?]*$/;
    return regex.test(text);
  }

  const getVideoIdFromUrl = (url) => {
    const sanitisedUrl = url.trim();
    if (!sanitisedUrl.trim()) {
      return null;
    }

    const youtubeUrlPattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = sanitisedUrl.match(youtubeUrlPattern);

    return match ? match[1] : null;
  }

  const handleSubmit = (e) => {
    console.log('Submit button clicked');
    e.preventDefault();

    setSearching(false);
    const videoId = getVideoIdFromUrl(query)
    if (videoId) {
      onUrlSubmit();
      setShowBannerMessage('');
      console.log('Valid video url submitted: ', query, videoId);
      youtube_api_call(searchUrl);
      return
    }
    if (validSearchText(query)) {
      onTextSubmit();
      setShowBannerMessage('');
      console.log('Valid search text submitted: ', query);
      youtube_api_call(searchKeyword);
      return
    }

    console.log('Invalid input entered: ', query);
    setShowBannerMessage('Please enter a valid youtube video URL');
    setTimeout(() => {
      setShowBannerMessage('');
    }, 3000);
    return;
  }

  const youtube_api_call = (action) => {
    window.gapi.client.setApiKey(config.youtube_search_key);
    window.gapi.client.load('youtube', 'v3', action);
  };

  const searchKeyword = () => {
    const request = window.gapi.client.youtube.search.list({
      part: 'snippet',
      type: 'video',
      q: query,
      maxResults: config.num_videos_to_fetch
    });
    request.execute(response => {
      console.log(response.result);
      getStatistics(response.result.items);
    });
  };

  const commaSeparatedIds = (items) => {
    return items.map(item => item.id.videoId).join(',');
  }

  const mergeStatisticsWithVideoInfo = (items, statistics) => {
    items.forEach(item => {
      const video = statistics.find(stat => stat.id === item.id.videoId);
      if (video) {
        item.statistics = video.statistics;
      }
    });
    console.log('------ Storeing search:', items);
    if (!alreadyStored) {
      let expiry = Date.now() + 360 * 60 * 60 * 1000;
      if (searchText === config.home_search_text) {
        expiry = Date.now() + 12 * 60 * 60 * 1000;
      }

      localStorage.setItem(searchText ? searchText : 'latestHomeSearchResults', JSON.stringify({ expiry: expiry, value: items }));
      // setServerSearchResults(searchText ? searchText : 'latestHomeSearchResults', JSON.stringify({ expiry: expiry, value: items }))
    }
    onSearchResults(items);
  }

  const getStatistics = (items) => {
    const request = window.gapi.client.youtube.videos.list({
      part: 'statistics',
      type: 'video',
      id: commaSeparatedIds(items)
    });
    request.execute(response => {
      console.log(response.result);
      mergeStatisticsWithVideoInfo(items, response.result.items);
    });
  }

  const searchUrl = () => {
    const id = getVideoIdFromUrl(query);
    fetch(`https://www.googleapis.com/youtube/v3/videos?id=${id}&key=${config.youtube_search_key}&part=snippet%2Cstatistics`)
      .then(response => response.json())
      .then(data => {
        if (data.items.length > 0) {
          const video = data.items[0];
          const { title, thumbnails } = video.snippet;
          console.log('Video details:', title, thumbnails.default.url);
          onUrlResult(video)
        } else {
          console.log('No video details found');
          setShowBannerMessage('No video with the URL found on YouTube. Please try video search.');
        }
      })
      .catch(error => console.error('Error fetching video details:', error));
  };

  const pasteClick = (e) => {
    e.preventDefault();
    navigator.clipboard.readText().then((clipboardContent) => {
      setQuery(clipboardContent);
    }).catch((error) => {
      console.error('Error reading clipboard:', error);
    });
  }

  return (
    <div>
      <div className="youtube-search-container">
        <div style={{ margin: '1%' }}>
          <form onSubmit={handleSubmit}>
            <div style={{ textAlign: 'center' }}>
              <InputGroup className="mb-4">
                <InputGroup.Text id="basic-addon1">
                  <Image style={{ paddingLeft: "10px", width: '50px', height: '30px' }} src={require("../assets/img/youtube_play_button.png")} />
                </InputGroup.Text>
                {query &&
                  <InputGroup.Text id="remove-search">
                    <CancelIcon onClick={onClear} />
                  </InputGroup.Text>}
                <Form.Control
                  id="query"
                  value={query}
                  type="text"
                  onChange={e => setQuery(e.target.value)}
                  placeholder="Search e.g. &quot;new&quot; or Paste Url for YouTube videos..."
                  aria-label="videoUrl"
                  aria-describedby="basic-addon1"
                />
                <InputGroup.Text id="basic-addon1">
                  <div style={{ textAlign: 'center' }}>
                    <button type="submit" className="btn btn-centre">
                      🔍
                    </button>
                  </div>
                </InputGroup.Text>
              </InputGroup>
            </div>

            {showBannerMessage !== '' && (<div style={{ marginBottom: "50px" }} className="red-banner-message">{showBannerMessage}</div>)}
            {searchText !== '' && searching && <h6 className='m_container' style={{ marginBottom: "16px", alignItems: "center" }}><CircularProgress color="secondary" /></h6>}


          </form>
        </div>
      </div >
    </div >
  )
}

export default YoutubeSearch;
