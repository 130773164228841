import Card from 'react-bootstrap/Card';
import { Accordion, AccordionHeader, Col, Container, Row } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import PieChartWrapper from './PieChartWrapper';
import React, { useState, useEffect } from 'react';
import config from '../config.json';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import TileCard from './TileCard';
import Stack from '@mui/material/Stack';
import SquareIcon from '@mui/icons-material/Square';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import AnalysisDataBroker from './AnalysisDataBroker';
import AnalysisLoading from './AnalysisLoading';
import ErrorModal from './ErrorModal';
import InfoIcon from '@mui/icons-material/Info';
import DeepAnalysis from './DeepAnalysis';

export default function AnalysisDashboard({ videoInfo, analysisData, onReturnToSearch, tCommsOnVid, onAnalysisDone }) {
    const totalCommentOnTheVideo = videoInfo.statistics.commentCount
    const [showErrorBannerMessage, setShowErrorBannerMessage] = useState('');
    const [showErrorDialog, setShowErrorDialog] = useState('')
    const [mappedDashboardData, setMappedDashboardData] = useState('')
    const [doMoreAnalysis, setDoMoreAnalysis] = useState(false)

    const startMoreAnalysis = () => {
        setDoMoreAnalysis(true)
    }

    const onLoadingClick = () => {
        console.log('Loading clicked ');
    }

    const extract_list = (data_to_extract_list) => {
        if (Array.isArray(data_to_extract_list)) {
            return data_to_extract_list.filter(item => typeof item === 'string');
        } else if (typeof data_to_extract_list === 'object' && data_to_extract_list !== null) {
            for (const key in data_to_extract_list) {
                const result = extract_list(data_to_extract_list[key]);
                if (result !== null) {
                    return result;
                }
            }
        }
        return null;
    };

    const onAnalysisDoneLocal = (videoid, data) => {
        setDoMoreAnalysis(false)
        const mapped_data = mapCommsData(data)
        setMappedDashboardData(mapped_data)
        onAnalysisDone(videoid, data)
    }

    function mapCommsData(inputData) {
        const positive_comments_count = inputData.all_positive_comment_ids.length
        const negative_comments_count = inputData.all_negative_comment_ids.length
        const neutral_comments_count = inputData.all_neutral_comment_ids.length
        const totalAnalysed = positive_comments_count + negative_comments_count + neutral_comments_count;
        const top_topics = inputData.top_topics;
        const fetch_count = inputData.fetch_count;
        const analysed_comments_count = inputData['analysed_comments_count'];
        const deepAnalysisStatus = inputData['deep_analysis_status'];
        console.log("***** Mapping analysed_comments_count", analysed_comments_count)


        // count total of comments on topics except topic name other
        const mappedTopics = top_topics.map(topic => {
            const positive_count = topic.positive_comment_ids.length
            const negative_count = topic.negative_comment_ids.length
            const neutral_count = topic.neutral_comment_ids.length
            const comment_count = positive_count + negative_count + neutral_count
            return {
                positive_count,
                positive_comments: topic.positive_comment_ids,
                negative_count,
                negative_comments: topic.negative_comment_ids,
                neutral_count,
                neutral_comments: topic.neutral_comment_ids,
                comment_count,
                topic_name: topic.topic_name,
                summary: topic.summary,
            }
        });

        const withoutOtherTopic = mappedTopics.filter(topic => topic.topic_name.toLowerCase() !== 'other')
        const other_topic = mappedTopics.find(topic => topic.topic_name.toLowerCase() === 'other');
        withoutOtherTopic.sort((a, b) => b.comment_count - a.comment_count);


        let totalCommentsOnTopics = 0;
        for (let i = 0; i < withoutOtherTopic.length; i++) {
            totalCommentsOnTopics += withoutOtherTopic[i].comment_count;
        }

        const new_other_topic = {}
        if (other_topic) {
            const total = totalCommentOnTheVideo < analysed_comments_count ? totalCommentOnTheVideo : analysed_comments_count;
            const current_total_on_other = other_topic.comment_count;
            const should_be_total_other = total - totalCommentsOnTopics;
            const should_be_positive_other = Math.round((should_be_total_other * other_topic.positive_count) / current_total_on_other);
            const should_be_negative_other = Math.round((should_be_total_other * other_topic.negative_count) / current_total_on_other);
            const should_be_neutral_other = Math.round((should_be_total_other * other_topic.neutral_count) / current_total_on_other);
            // replace the current total on other with the new total
            new_other_topic.comment_count = should_be_total_other >= 0 ? should_be_total_other : 0;
            new_other_topic.positive_count = should_be_positive_other >= 0 ? should_be_positive_other : 0;
            new_other_topic.negative_count = should_be_negative_other >= 0 ? should_be_negative_other : 0;
            new_other_topic.neutral_count = should_be_neutral_other >= 0 ? should_be_neutral_other : 0;
            new_other_topic.summary = other_topic.summary;
        }

        new_other_topic.topic_name = 'All Other Topics'
        new_other_topic.summary = 'All other topics that are not included in above topics.'
        const sortedTopics = [...withoutOtherTopic, new_other_topic]

        return {
            positive_comments_count,
            negative_comments_count,
            neutral_comments_count,
            ideas: extract_list(inputData.ideas),
            suggestions: extract_list(inputData.suggestion),
            total_analysed: totalAnalysed,
            positive_percentage: Math.ceil((positive_comments_count / totalAnalysed) * 100),
            negative_percentage: Math.ceil((negative_comments_count / totalAnalysed) * 100),
            neutral_percentage: Math.ceil((neutral_comments_count / totalAnalysed) * 100),
            top_topics: sortedTopics,
            fetch_count,
            analysed_comments_count,
            deepAnalysisStatus
        }
    }

    useEffect(() => {
        try {
            const mapped_data = mapCommsData(analysisData)
            setMappedDashboardData(mapped_data)
            return
        }
        catch (error) {
            setShowErrorDialog('Something went wrong. Please try again later. (The issue has been reported.)')
            console.log('Error in data', error)
        }
    }, [])

    return (
        <div>
            {showErrorDialog !== '' && <ErrorModal message={showErrorDialog} onClose={() => onReturnToSearch()}></ErrorModal>}
            <div className='m_container' style={{ marginBottom: "20px" }}><Button variant='outline-dark' onClick={() => onReturnToSearch()}><ArrowCircleLeftIcon /> Back to videos</Button></div>
            <h6 className='m_container' style={{ marginBottom: "16px", alignItems: "center" }}>Comments Analysis</h6>
            <Col key={videoInfo.id}>
                <Card>
                    <Card.Body>
                        <Card.Text>
                            <div style={{ textAlign: "center", paddingBottom: "10px" }}>
                                <iframe width="364px" height="204.74" src={config.video_url_embed_prefix + videoInfo.id} title={videoInfo.title} allowFullScreen></iframe>
                            </div>
                            <div style={{ textAlign: "center" }}><h5>
                                🎥 <span dangerouslySetInnerHTML={{ __html: videoInfo.title }} />
                            </h5></div>
                            {/* TODO: Add Video likes and number of comments  */}
                        </Card.Text>
                    </Card.Body>
                </Card >
            </Col >
            {mappedDashboardData && videoInfo.statistics.commentCount > mappedDashboardData.analysed_comments_count &&
                < h6 className='m_container' style={{ marginTop: "20px", marginBottom: "20px", alignItems: "left" }}>{"Analysed " + mappedDashboardData.analysed_comments_count + " out of " + videoInfo.statistics.commentCount + " comments."}</h6>
            }
            {mappedDashboardData && videoInfo.statistics.commentCount <= mappedDashboardData.analysed_comments_count &&
                < h6 className='m_container' style={{ marginTop: "20px", marginBottom: "20px", alignItems: "left" }}>{"Analysed all of " + videoInfo.statistics.commentCount + " comments."}</h6>
            }
            {<LinearProgress variant="determinate" value={100 * mappedDashboardData.analysed_comments_count / videoInfo.statistics.commentCount} />}
            {videoInfo.statistics.commentCount > mappedDashboardData.analysed_comments_count &&
                <>
                    {
                        doMoreAnalysis === false
                        && analysisData.fetch_count < config.max_fetch_count
                        && videoInfo.statistics.commentCount > (mappedDashboardData.analysed_comments_count + 90)
                        && <div className='m_container' style={{ marginTop: "20px", marginBottom: "20px" }}>
                            <Button onClick={() => startMoreAnalysis()} style={{ backgroundColor: "#B800D8" }} variant="secondary">
                                Analyse More Comments<RocketLaunchIcon />
                            </Button>
                        </div>
                    }
                    {
                        doMoreAnalysis
                        &&
                        <>
                            <div style={{ className: "m_container", marginTop: "20px", color: "blue", alignItems: "left", fontSize: "12px" }}>
                                <AnalysisLoading onClick={() => onLoadingClick()}></AnalysisLoading>
                            </div>
                            <AnalysisDataBroker videoId={videoInfo.id} videoTitle={videoInfo.title} tCommsOnVid={videoInfo.statistics.commentCount} onAnalysisDone={onAnalysisDoneLocal} additionalCommentsAnalysis={true} />
                        </>
                    }
                </>
            }
            {showErrorBannerMessage !== '' && (<div className="red-banner-message">{showErrorBannerMessage}</div>)}
            {
                <div>
                    <Accordion style={{ alignItems: "center", marginTop: "20px" }} defaultActiveKey="0">
                        <AccordionHeader>
                            <h6 className='m_container' style={{ marginBottom: "20px", alignItems: "center" }}>📌 Information</h6>
                        </AccordionHeader>
                        <Accordion.Body>

                            <Stack direction="column" spacing={2} style={{ alignItems: "centre", marginBottom: "20px" }}>
                                <span><SquareIcon style={{ backgroundColor: "#02B2AF", color: "#02B2AF" }}> </SquareIcon><h8>  This color represents positive sentiments</h8></span>
                                <span><SquareIcon style={{ backgroundColor: "#B800D8", color: "#B800D8" }}> </SquareIcon><h8>  This color represents negative sentiments</h8></span>
                                <span><SquareIcon style={{ backgroundColor: "#2D96FF", color: "#2D96FF" }}> </SquareIcon><h8>  This color represents neutral sentiments</h8></span>
                                <span><InfoOutlinedIcon /><h8>  Comments can belong to multiple topics, so total and topic counts may differ.</h8></span>
                                <span><InfoOutlinedIcon /><h8>  Comment may not be right category of the sentiments (Positive, Negative, Neutral).</h8></span>
                                <span><InfoOutlinedIcon /><h8>  There may be issue with presented data. Please refer to terms of service. Application is under constant improvements.</h8></span>
                            </Stack>
                        </Accordion.Body>
                    </Accordion>
                </div>
            }
            {
                mappedDashboardData !== '' &&
                <div style={{ margin: '10px', marginTop: '40px' }}>

                    <div>
                        <h6 className='m_container' style={{ marginBottom: "20px", alignItems: "center" }}>⭐ Top Topics</h6>

                        <Container style={{ marginBottom: '30px' }}>
                            <Row xs={1} md={3} className="g-4">
                                {mappedDashboardData.top_topics.map((topic, index) => (
                                    <Col key={videoInfo.id + topic + index} style={{ marginBottom: '20px', paddingTop: '10px', paddingLeft: '1px', paddingRight: '1px' }}>
                                        <TileCard title={topic.topic_name} total={topic.comment_count} positives={topic.positive_count} negatives={topic.negative_count} neutrals={topic.neutral_count} summary={topic.summary} positiveComments={topic.positive_comments} negativeComments={topic.negative_comments} neutralComments={topic.neutral_comments} video_id={config.video_url_embed_prefix + videoInfo.id} />
                                    </Col>
                                ))}
                            </Row>
                        </Container>

                        <Container>
                            <Row xs={1} md={3} className="g-4">

                                {mappedDashboardData.ideas &&
                                    <Col style={{ marginBottom: '20px', paddingTop: '10px' }}>

                                        <Card key="ideas" className='smallcard' style={{ borderRadius: '30px', border: 'none', background: '#fffff' }}>
                                            <h6 className='m_container' style={{ marginBottom: "20px", alignItems: "center" }}>💡Top Ideas</h6>
                                            <Card.Body>
                                                <div>
                                                    {mappedDashboardData.ideas.map((idea, index) => (
                                                        <div className="btn btn-secondary btn-centre" style={{ border: 'none', color: 'black', background: 'rgb(173, 216, 215)', margin: '2px', borderRadius: '5px' }}>
                                                            🚀 {idea}
                                                        </div>
                                                    ))}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                }
                                {mappedDashboardData.suggestions &&
                                    <Col style={{ marginBottom: '20px', paddingTop: '10px' }}>
                                        <Card className='smallcard' style={{ borderRadius: '30px', border: 'none', background: '#fffff' }}>
                                            <h6 className='m_container' style={{ marginBottom: "20px", alignItems: "center" }}>🌱 Suggestion comments</h6>
                                            <Card.Body>
                                                {mappedDashboardData.suggestions.map((comment, index) => (
                                                    <div style={{ background: 'rgb(247 230 158)', margin: '2px', borderRadius: '5px' }}>
                                                        💭 "{comment}"
                                                    </div>
                                                ))}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                }
                                {
                                    <Col style={{ marginBottom: '20px', paddingTop: '10px' }}>

                                        <Card className='smallcard' style={{ borderRadius: '30px', border: 'none', background: '#fffff' }}>
                                            <h6 className='m_container' style={{ marginBottom: "20px", alignItems: "center" }}>🎭 Overall comments sentiment</h6>
                                            <Card.Body>
                                                <PieChartWrapper positiveCount={mappedDashboardData.positive_percentage} negativeCount={mappedDashboardData.negative_percentage} neutralCount={mappedDashboardData.neutral_percentage} />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                }
                            </Row>
                        </Container>

                        {<div className='m_container' style={{ marginTop: "20px", marginBottom: "20px" }}> <DeepAnalysis currentStatusInput={analysisData.deepAnalysisStatus} /></div>}
                        {<div className='m_container' style={{ marginBottom: "100px" }}> We will get in touch when Deep Analysis is ready. *This feature is only available for subscribed members.</div>}


                        <Container>
                            <h4 className='m_container' style={{ color: 'rgb(203 81 228)', marginBottom: "20px", alignItems: "center" }}>Coming soon! Pioneering, never before seen features 🚀</h4>

                            <Row xs={1} md={3} className="g-4">
                                {
                                    <div style={{ textAlign: "center" }}>

                                        <Col style={{ marginBottom: '20px', paddingTop: '10px' }}>
                                            <Image style={{ width: '70px', height: '70px', borderRadius: '60px' }} src={require("../assets/img/chatbot.png")} />
                                            <div>Chat with comments (data)</div>
                                        </Col>
                                    </div>
                                }
                                {
                                    <div style={{ textAlign: "center" }}>

                                        <Col style={{ marginBottom: '20px', paddingTop: '10px' }}>
                                            <Image style={{ width: '70px', height: '70px', borderRadius: '60px' }} src={require("../assets/img/auto_reply.png")} />
                                            <div>Auto Reply (Comments)</div>
                                        </Col>
                                    </div>
                                }
                                {
                                    <div style={{ textAlign: "center" }}>
                                        <Col style={{ marginBottom: '20px', paddingTop: '10px' }}>
                                            <Image style={{ width: '70px', height: '70px', borderRadius: '60px' }} src={require("../assets/img/live_campaign.png")} />
                                            <div>Live Campaign</div>
                                        </Col>

                                    </div>
                                }

                            </Row>
                        </Container>
                    </div>
                </div >
            }
            <div className='m_container' style={{ marginBottom: "20px" }}><Button variant='outline-dark' onClick={() => onReturnToSearch()}><ArrowCircleLeftIcon /> Back to videos</Button></div>
        </div >
    );

}
