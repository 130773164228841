import React from 'react';
import { Navbar } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';


function NavbarContentSubHeader() {
  return (
    <>
      {
        <div>
          <Navbar className="bg-body-tertiary mb-3 bgimage d-flex">
            <Container fluid>
            </Container>
          </Navbar>
        </div>
      }
    </>
  );
}

export default NavbarContentSubHeader;
