import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

export default function TestPage() {
  return (
    <div>
      <h4>Test Page 1</h4>
      <PayPalScriptProvider options={{
        "client-id": "KYWRLEL9GR5J2",
        "currency": "USD"
      }}>
        <PayPalButtons> pay</PayPalButtons>
      </PayPalScriptProvider>
    </div>
  );
}

