import React, { useState } from 'react';
import YoutubeSearch from './YoutubeSearch';
import Dashboard from './Dashboard';
import { Nav, Tab, Button, Card, Row, Col } from 'react-bootstrap';
import config from '../config.json';

const LandingPage = () => {
    console.log('LandingPage component loaded');
    const [urlVideoResult, setUrlVideoResult] = useState('');
    const [results, setResults] = useState('');
    const [analysedList, setAnalysedList] = useState([]);
    const [searchPage, setSearchPage] = useState('')


    function onUrlInputSubmit() {
        console.log('LandingPage > onUrlInputSubmit called');
        setSearchPage('url');
    }

    function onTextInputSubmit() {
        console.log('LandingPage > onTextInputSubmit called');
        setSearchPage('text');
    }

    function onAnalyse(itemToAnalyse) {
        console.log('LandingPage > onAnalyse called with videoId: ', itemToAnalyse);
        setAnalysedList([...analysedList, itemToAnalyse.id.videoId]);
    }

    function onSearchResults(textSearchResults) {
        console.log('LandingPage > onSearchResults called with results: ', textSearchResults);
        setResults(textSearchResults);
    }

    function onUrlResult(urlResult) {
        console.log('LandingPage > onUrlResult called with urlResult: ', urlResult);
        setUrlVideoResult(urlResult)
    }

    return (
        <div>
            <YoutubeSearch onUrlSubmit={onUrlInputSubmit} onTextSubmit={onTextInputSubmit} onSearchResults={onSearchResults} onUrlResult={onUrlResult} />
            {searchPage === 'text' && results !== '' &&
                (<div style={{ paddingLeft: "30px" }}>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className='m_container'>
                            <div className='m_box' style={{ height: "500px", overflowY: "scroll", paddingRight: "60px", marginLeft: "auto" }}>
                                <Nav variant="pills" className="flex-column">
                                    {results.map((item, index) => (
                                        <Nav.Item>
                                            <Row>
                                                <Nav.Link eventKey={item.id.videoId}>
                                                    <Card style={{ width: '18rem' }}>
                                                        {/* <Card.Img variant="top" style={{ paddingLeft: "20px", paddingTop: "20px", width: "150px", height: "90px" }} src={item.snippet.thumbnails.default.url} /> */}
                                                        <iframe width="288px" height="150px" src={"https://www.youtube.com/embed/" + item.id.videoId} frameborder="0" allowfullscreen></iframe>
                                                        <Card.Body>
                                                            <Card.Text>
                                                                {item.snippet.title}
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Nav.Link>
                                            </Row>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                            </div>
                            <div className='m_box' style={{ paddingRight: "20px", minWidth: "1000px", marginRight: "auto" }}>
                                <Tab.Content>
                                    {results.map((item, index) => (
                                        <Tab.Pane eventKey={item.id.videoId}>
                                            <div>
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <iframe width="288px" height="150px" src={config.video_url_embed_prefix + item.id.videoId} frameborder="0" allowfullscreen></iframe>
                                                    <div className='video-title'>
                                                        {item.snippet.title}
                                                    </div>
                                                </div> </div>
                                            {!analysedList.includes(item.id.videoId) &&
                                                <div className='m_container2'>
                                                    <Button onClick={() => onAnalyse(item)} variant="btn btn-dark btn-centre">Analyse</Button>
                                                </div>
                                            }
                                            {analysedList.includes(item.id.videoId) && <Dashboard videoLink={config.video_url_prefix + item.id.videoId} />}
                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </div>)
            }
            {
                searchPage === 'url' && urlVideoResult !== '' &&
                (
                    <div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <iframe width="288px" height="150px" src={config.video_url_embed_prefix + urlVideoResult.id} frameborder="0" allowfullscreen></iframe>
                            <div className='video-title'>
                                {urlVideoResult.snippet.title}
                            </div>
                        </div>
                        <div>
                            <Dashboard videoLink={config.video_url_prefix + urlVideoResult.id} />
                        </div>
                    </div>
                )
            }
        </div >
    );
}

export default LandingPage;