import axios from 'axios';
import config from '../config.json';


export const api_post = async (input_data, url_path) => {
  function getCommsAPIURL() {
    if (config.env === "live") {
      console.log("Using live API")
      return "https://comms-api-1.azurewebsites.net/api/" + url_path
    }
    else {
      console.log("API POST: Using local API")
      return "http://localhost:7071/api/" + url_path;
    }
  }

  axios.post(getCommsAPIURL(), input_data, {
    headers: {
      'Content-Type': 'application/json'
    },
  })
    .then(response => {
      console.log("API call successful: " + url_path)
      console.log(response)
    })
    .catch(error => {
      console.error('Error registering user request: ', url_path, error);
    });
  return
}
