import React, { useState } from 'react';
import { Navbar, Image } from 'react-bootstrap';
import FancyLogin from './FancyLogin';
import FancyLogout from './FancyLogout';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Home } from '@mui/icons-material';
import Badge from '@mui/material/Badge';
import VideocamIcon from '@mui/icons-material/Videocam';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import SearchIcon from '@mui/icons-material/Search';


function NavbarContent({ authenticationButton, onLogin, onLogout, analysisCount }) {
  const [expand, setExpand] = useState(false);

  const handleHome = () => {
    window.location.href = '/';
  }

  return (
    <>
      {
        <div>
          <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
            <Container fluid>
              <Navbar.Brand href="/">
                <Image style={{ paddingRight: "3px", paddingLeft: "5px", width: '40px', height: '30px', borderRadius: '60px' }} src={require("../assets/img/logo.png")} />
                <span style={{ fontSize: "20px", color: "#c98cc9" }}>Comments AI</span>
                <span style={{ paddingLeft: "6px", color: "black", fontSize: "11px", alignItems: "center" }}><span style={{ color: "#9196d3" }}>
                  Analysis by users:  count
                  <Badge badgeContent={analysisCount} color="success">

                  </Badge></span></span>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    Comments AI
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link href="/">
                      <Button variant="light">
                        <Home /> Home
                      </Button>
                    </Nav.Link>
                    <Nav.Link href="/">
                      <Button variant="light">
                        <SearchIcon /> Search
                      </Button>
                    </Nav.Link>
                    <Nav.Link href="/analysissamples">
                      <Button variant="light">
                        <EqualizerIcon /> Analysis Samples
                      </Button>
                    </Nav.Link>
                    <Nav.Link>
                      {authenticationButton === 'SignIn' ? <FancyLogin onLogin={onLogin} /> : <FancyLogout onLogout={onLogout} />}
                    </Nav.Link>
                  </Nav>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link href="https://lambodhi.com">
                      <Button variant="light">
                        Lambodhi.com
                      </Button>
                    </Nav.Link>
                  </Nav>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Nav.Link>

                      <Button disabled variant="light">
                        Version: 0.2.0
                      </Button>
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </div>
      }
    </>
  );
}

export default NavbarContent;
