import React, { Fragment, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './assets/css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarContent from './components/NavbarContent';
import NavbarContentSubHeader from './components/NavbarContentSubHeader';
import LandingPage from './components/LandingPage';
import FancyLogout from './components/FancyLogout';
import { useEffect } from 'react';
import { gapi } from 'gapi-script';
import HomePage from './components/HomePage';
import HomeTabM from './components/HomeTabM';
import config from './config.json';
import AdminPage from './components/AdminPage';
import { api_post } from './components/UserActivity';
import axios from 'axios';
import ReactGA from 'react-ga';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import VideoComposerTab from './components/VideoComposerTab';
import TestPage from './components/TestPage';
import SamplePage from './components/SamplePage';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { Home } from '@mui/icons-material';



const clientId = config.google_auth_clientId

function App() {
  const [user, setUser] = useState(null);
  const [authenticationButton, setAuthenticationButton] = useState('SignIn');
  const [apiUpdateCallInProgress, setApiUpdateCallInProgress] = useState(false);
  const [analysisCount, setAnalysisCount] = useState("21,1513+");
  const [bottomNavValue, setBottomNavValue] = useState(0);
  const [pathName, setPathName] = useState(window.location.pathname);

  useEffect(() => {
    // Set the value of BottomNavigation based on the current location
    switch (pathName) {
      case '/':
        setBottomNavValue(0);
        break;
      case '/analysissamples':
        setBottomNavValue(1);
        break;
      default:
        setBottomNavValue(-1); // Set to an invalid index to deselect all buttons
        break;
    }
  }, []);

  const handleChange = (event, newValue) => {
    setBottomNavValue(newValue);
    // Perform navigation based on the selected value
    switch (newValue) {
      case 0:
        window.location.href = '/'; // Navigate to the home page
        setPathName('/')
        break;
      case 1:
        window.location.href = '/analysissamples'; // Navigate to the analysis samples page
        setPathName('/')
        break;
      case 2:
        window.location.href = 'https://lambodhi.com'; // Navigate to an external URL
        setPathName('/')
        break;
      default:
        break;
    }
  };

  const send_update_to_server = async (user_email, user_id, localUserData, token, storedAt) => {
    if (apiUpdateCallInProgress) {
        console.log("API call already in progress...")
        return;
    }

    setApiUpdateCallInProgress(true)

    const input_data = {
        "app_id": "1ecead0c-d3e1-4bc8-b5a3-757b94f2012f",
        "api_key": "228031c9-a95d-40b2-TempSwift1dot-df659df60e86",
        "email": user_email,
        "user_id": user_id,
        "auth_token": token
    };

    api_post(input_data, "first_call")
  }

  const onLogin = (googleUser) => {
    console.log('(From App.js) User logged in as following: ')
    console.log(googleUser)
    // console.log(googleUser.email)
    setUser(googleUser);
    setAuthenticationButton('SignOut');
    console.log("In  onlogin authenticationButton") 
    console.log(authenticationButton) 
    // After successful login
    localStorage.removeItem('userData')
    // Store user data in local storage
    const { email, aud, sub } = googleUser
    let storedAtDateTime = new Date().getTime()
    if (config.env === 'dev') {
      const today = new Date();
      storedAtDateTime = new Date(today.getFullYear() + 2, today.getMonth(), today.getDate());
    }

    
    const userData = {
      token: aud,
      email: email,
      user_id: sub,
      storedAt: storedAtDateTime
    };
    localStorage.setItem('userData', JSON.stringify(userData));
    send_update_to_server(email, sub, userData, aud, storedAtDateTime)
    console.log("Sent update to server.")
  }

  const isUserLoggedIn = () => { 
    const localUserData = JSON.parse(localStorage.getItem('userData'))
    if (localUserData) {
      const storedAt = localUserData.storedAt
      if (expired_login_session(storedAt)) {
        localStorage.removeItem('userData')
        return false
      }
      else {
        return true
      }
    }
    return false
  }

  function expired_login_session(storedDateTime) {
    try {
        const storedDate = new Date(storedDateTime);

        const currentDate = new Date();
        // Set expiry date time to 3 hours after the stored date time
        const expiryDate = new Date(storedDate.getTime() + 3 * 60 * 60 * 1000)

        if (expiryDate.getTime() < currentDate.getTime()) {
            return true;
        }

        return false;
    } catch (error) {
        return true;
    }
  }

  const onLogout = () => {
    console.log('(From App.js) User logged out!')
    setUser('');
    setAuthenticationButton('SignIn');
    localStorage.removeItem('userData')
    window.location.href = "/";
  }

  const update_analysis_count_on_server = async () => {
    const input_data = {
      "app_id": "1ecead0c-d3e1-4bc8-b5a3-757b94f2012f",
      "api_key": "228031c9-a95d-40b2-TempSwift1dot-df659df60e86",
    };

    api_post(input_data, "analysiscount")
  }

  const increment_analysis_count = () => {
    console.log("Incrementing analysis count.")
    const count = analysisCount.replace(',', '')
    const new_count = parseInt(count) + 1
    const formatted_new_count = new_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    setAnalysisCount(formatted_new_count)
    localStorage.setItem("analysis_count", formatted_new_count)
    update_analysis_count_on_server()
  }

  const get_analysis_count = (first=false) => {
    const locally_stored_count = localStorage.getItem('analysis_count')
    if (!first && locally_stored_count) {
      setAnalysisCount(locally_stored_count)
      return
    }

    const url_path = "analysiscount"
    const input_data = {
      "app_id": "1ecead0c-d3e1-4bc8-b5a3-757b94f2012f",
      "api_key": "228031c9-a95d-40b2-TempSwift1dot-df659df60e86",
    };
  
    function getCommsAPIURL() {
      if (config.env === "live") {
        console.log("Using live API")
        return "https://comms-api-1.azurewebsites.net/api/" + url_path
      }
      else {
        console.log("Analysis Count: Using local API")
        return "http://localhost:7071/api/" + url_path;
      }
    }
  

    // make an api call
    axios.get(getCommsAPIURL(), input_data, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then(response => {
        console.log("API call successful: " + url_path)
        console.log(response.data.analysis_count)
        const count = response.data ?  response.data.analysis_count.toString() : "21,1513+" 
        localStorage.setItem('analysis_count', count)
        setAnalysisCount(count)
      })
      .catch(error => {
        console.error('Error getting count: ', url_path, error);
      });
  }

  useEffect(() => {
    ReactGA.initialize('G-LB80J9P3P9');
    ReactGA.pageview(window.location.pathname)

    if (isUserLoggedIn()) {
      console.log("User is logged in")
      setAuthenticationButton('SignOut');
    }

    function start() {
      gapi.client.init({
        client_id: clientId,
        scope: ''
      })
    };

    gapi.load('client:auth2', start);

    // get analysis count from server
    get_analysis_count()
  });


  return (
    <BrowserRouter>
      <Fragment>
        <NavbarContent authenticationButton={authenticationButton} onLogin={onLogin} onLogout={onLogout} analysisCount={analysisCount} />
        <NavbarContentSubHeader />
        <Routes  basename="/app">
          <Route path="/oldhome" element={isUserLoggedIn() ? <LandingPage/>  : <HomePage onLogin={onLogin} />} />
          <Route path="/" element={isUserLoggedIn() ? <HomeTabM increment_analysis_count={increment_analysis_count} />  : <HomePage onLogin={onLogin} />} />
          <Route path="/videocomposer/:ideaid" element={isUserLoggedIn() ? <VideoComposerTab/>  : <HomePage onLogin={onLogin} />} />
          <Route path="/logout" element={<FancyLogout onLogout={onLogout} />} />
          <Route path="/supadmin" element={isUserLoggedIn() ? <AdminPage /> : <HomePage onLogin={onLogin} />} />
          <Route path="/test" element={<TestPage />} />
          <Route path="/analysissamples" element={<SamplePage />} />
          
        </Routes>
        <BottomNavigation
      showLabels
      value={bottomNavValue}
      onChange={handleChange}
      style={{ marginTop: '50px' }}
    >
      <BottomNavigationAction label="Home" icon={<Home />} />
      <BottomNavigationAction label="Analysis Samples" icon={<EqualizerIcon />} />
      <BottomNavigationAction label="Lambodhi.com" icon={<HomeWorkIcon />} />
    </BottomNavigation>
      </Fragment>
      </BrowserRouter>
  );
}

export default App;
