import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from 'react-bootstrap';

import LinearProgress from '@mui/material/LinearProgress';

export default function CommentDrawer({ commentsType, open, handleClose, positiveCommentsText, negativeCommentsText, neutralCommentsText, loading }) {
  return (
    <>
      <Drawer
        anchor='bottom'
        open={open}
        onClose={() => handleClose()}
        style={{ height: '50%' }}
      >
        {loading && <div><LinearProgress color="secondary" /></div>}
        <div>
          <Button variant="outline" onClick={() => handleClose()} style={{ marginTop: "10px", float: 'right', marginRight: '10px' }}><CloseIcon /></Button>

          {commentsType === 'positive' &&
            <>
              <h6 className='m_container' style={{ marginTop: "20px", marginBottom: "16px", alignItems: "center" }}>Positive Comments</h6>
              <div style={{ height: '500px' }} >
                {positiveCommentsText && positiveCommentsText.length > 0 && positiveCommentsText.map((item) => (
                  <div style={{ marginBottom: "5px" }}>
                    <div className="btn btn-secondary btn-centre" style={{ border: 'none', color: 'black', background: 'rgb(173, 216, 215)', margin: '2px', borderRadius: '5px' }}>
                      💬 <span onClick={() => {
                        const content = item.comment;
                        navigator.clipboard.writeText(content)
                          .then(() => {
                            console.log('Content copied successfully!');
                          })
                          .catch(err => {
                            console.error('Failed to copy content: ', err);
                          });
                      }} style={{ cursor: 'pointer' }} dangerouslySetInnerHTML={{ __html: item.comment }} />
                    </div>
                  </div>
                ))}
                {positiveCommentsText && positiveCommentsText.length === 0 &&
                  <h7 className='m_container' style={{ marginTop: "20px", marginBottom: "16px", alignItems: "center" }}>No Positive Comments</h7>
                }
              </div>
            </>
          }
          {commentsType === 'negative' &&
            <>
              <h6 className='m_container' style={{ marginTop: "20px", marginBottom: "16px", alignItems: "center" }}>Negative Comments</h6>

              <div style={{ height: '500px' }} >
                {negativeCommentsText && negativeCommentsText.map((item) => (
                  <div style={{ marginBottom: "5px" }}>
                    <div className="btn btn-secondary btn-centre" style={{ border: 'none', color: 'black', background: 'rgb(223 145 209)', margin: '2px', borderRadius: '5px' }}>
                      💬 <span onClick={() => {
                        const content = item.comment;
                        navigator.clipboard.writeText(content)
                          .then(() => {
                            console.log('Content copied successfully!');
                          })
                          .catch(err => {
                            console.error('Failed to copy content: ', err);
                          });
                      }} style={{ cursor: 'pointer' }} dangerouslySetInnerHTML={{ __html: item.comment }} />
                    </div>
                  </div>
                ))}
                {negativeCommentsText && negativeCommentsText.length === 0 &&
                  <h7 className='m_container' style={{ marginTop: "20px", marginBottom: "16px", alignItems: "center" }}>No Negative Comments</h7>
                }
              </div>
            </>
          }
          {commentsType === 'neutral' &&
            <>
              <h6 className='m_container' style={{ marginTop: "20px", marginBottom: "16px", alignItems: "center" }}>Neutral Comments</h6>

              <div style={{ height: '500px' }} >
                {neutralCommentsText && neutralCommentsText.map((item) => (
                  <div style={{ marginBottom: "5px" }}>
                    <div className="btn btn-secondary btn-centre" style={{ border: 'none', color: 'black', background: 'rgb(140 177 235)', margin: '2px', borderRadius: '5px' }}>
                      💬 <span onClick={() => {
                        const content = item.comment;
                        navigator.clipboard.writeText(content)
                          .then(() => {
                            console.log('Content copied successfully!');
                          })
                          .catch(err => {
                            console.error('Failed to copy content: ', err);
                          });
                      }} style={{ cursor: 'pointer' }} dangerouslySetInnerHTML={{ __html: item.comment }} />
                    </div>
                  </div>
                ))}
                {neutralCommentsText && neutralCommentsText.length === 0 &&
                  <h7 className='m_container' style={{ marginTop: "20px", marginBottom: "16px", alignItems: "center" }}>No Neutral Comments</h7>
                }
              </div>

            </>
          }
        </div>
      </Drawer >
    </>
  );

}
