import { googleLogout } from '@react-oauth/google';
import Button from 'react-bootstrap/Button';
import { Logout } from '@mui/icons-material';


function FancyLogout({ onLogout }) {

  function logout() {
    googleLogout();
    onLogout();
  }

  return (
    <Button onClick={logout} variant="light">
      <Logout /> Logout
    </Button>
  )
}

export default FancyLogout;