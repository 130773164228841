
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config.json';
import Errors from './Errors';
import { api_post } from './UserActivity';
import ReactGA from 'react-ga';
import AnalysisType from './AnalysisRequestType';

export default function AnalysisDataBroker({ videoId, videoTitle, tCommsOnVid, onAnalysisDone, additionalCommentsAnalysis }) {
    console.log("AnalysisDataBroker component called with videoLink: ", videoId)
    const [data, setData] = useState(null);
    const [apiCallInProgress, setApiCallInProgress] = useState(false);
    const [callCount, setCallCount] = useState(0);

    const mock_data = {
        "ideas": [
            "Comparative review with Sony headphones",
            "In-depth analysis of Soundcore app features",
            "Tutorial on optimizing noise cancellation settings",
            "Interview with a Soundcore product designer",
            "Community showcase of different customization options"
        ],
        "suggestion": [
            "Consider addressing the concerns raised about sound quality, build quality, and price to improve overall customer satisfaction."
        ],
        "top_topics": [
            {
                "summary": "People are expressing their opinions on the sound quality of the headphones, with some praising it for being impressive while others critiquing it as overrated.",
                "topic_name": "Sound Quality",
                "neutral_comment_ids": [
                    "Ugzj3vUB6g3eYT1q0Rp4AaABAg",
                    "Ugzdt2PuRcVhDrPNswd4AaABAg",
                    "Ugw2rA55mC-k17t9lLx4AaABAg"
                ],
                "negative_comment_ids": [
                    "UgwPreVKPhAwYr8PD6h4AaABAg",
                    "Ugwte8yY07iauXDf7pZ4AaABAg.A-wk-dGjsh6A0D6qQp9VPs",
                    "UgwL0tc1Nn-ie6-U07N4AaABAg",
                    "UgyLibX2y6f8HUqfukZ4AaABAg.A-_xMdiax-bA09wHRsO6ML"
                ],
                "positive_comment_ids": [
                    "Ugz3XR7bpvZ1Ra3IMy94AaABAg",
                    "UgwkZbf0crAAQecCudN4AaABAg",
                    "UgzP0990k2D7EpQMkLB4AaABAg",
                    "Ugx4oWyVY1CA6CcLT0h4AaABAg"
                ]
            },
            {
                "summary": "Discussions revolve around the build quality of the headphones, with mixed opinions about durability and design.",
                "topic_name": "Build Quality",
                "neutral_comment_ids": [],
                "negative_comment_ids": [
                    "UgyCzs5keAH78mmwVu54AaABAg"
                ],
                "positive_comment_ids": [
                    "UgwJ9HWTvI8L1-u__lt4AaABAg",
                    "UgyJsfZj22h8_GKrCxp4AaABAg",
                    "Ugwte8yY07iauXDf7pZ4AaABAg.A-wk-dGjsh6A09w0XVsZa-"
                ]
            },
            {
                "summary": "Comments vary regarding the price of the headphones, with some finding it affordable and worthy, while others consider it high or not justifiable.",
                "topic_name": "Price",
                "neutral_comment_ids": [
                    "UgwKElKYNUWg8eEgYLt4AaABAg",
                    "UgyR-jZyHp7w9PmvK4N4AaABAg"
                ],
                "negative_comment_ids": [
                    "Ugygw-KZcz_j7yFAOvd4AaABAg",
                    "Ugzi2HKCGaWKuAYK_5Z4AaABAg",
                    "UgyOgNw0LixSWiiqeXV4AaABAg",
                    "UgzTVZzK8Xbk-b6KBll4AaABAg"
                ],
                "positive_comment_ids": [
                    "UgwXGIw-cGGNDWelZ_94AaABAg",
                    "Ugx4oWyVY1CA6CcLT0h4AaABAg",
                    "UgwBqqoi532juJ3q4IB4AaABAg"
                ]
            },
            {
                "summary": "Opinions differ on the design of the headphones, with some appreciating it while others finding it unattractive or impractical.",
                "topic_name": "Design",
                "neutral_comment_ids": [
                    "Ugxk0LlF82bkXfBPQkd4AaABAg",
                    "Ugy6lXmK6NC1iXNuEqF4AaABAg"
                ],
                "negative_comment_ids": [
                    "UgxCdoGUFFGiOVs_dqh4AaABAg",
                    "UgzFMiJrHLMwmVFYGsJ4AaABAg",
                    "Ugxmxr1qHHSM-TAci0x4AaABAg"
                ],
                "positive_comment_ids": [
                    "UgyhQpTW0TXOG4X5r1B4AaABAg",
                    "Ugzdt2PuRcVhDrPNswd4AaABAg"
                ]
            },
            {
                "summary": "The effectiveness of the active noise cancellation feature is a point of contention among commenters, with some praising it while others expressing dissatisfaction.",
                "topic_name": "ANC (Active Noise Cancellation)",
                "neutral_comment_ids": [
                    "Ugw2rA55mC-k17t9lLx4AaABAg"
                ],
                "negative_comment_ids": [
                    "UgxrPYNDwhvC0ditEXd4AaABAg",
                    "Ugzo3XEEm54PV-pxpBF4AaABAg",
                    "UgwPreVKPhAwYr8PD6h4AaABAg"
                ],
                "positive_comment_ids": [
                    "Ugxrs60HodqgOeqc4L14AaABAg",
                    "UgyW7qpl4OYPrsb2dSV4AaABAg"
                ]
            },
            {
                "summary": "Various miscellaneous topics are discussed in the comments section, covering a range of opinions and suggestions.",
                "topic_name": "Other",
                "neutral_comment_ids": [
                    "UgyhQpTW0TXOG4X5r1B4AaABAg",
                    "Ugzdt2PuRcVhDrPNswd4AaABAg",
                    "Ugy6lXmK6NC1iXNuEqF4AaABAg"
                ],
                "negative_comment_ids": [
                    "UgwKElKYNUWg8eEgYLt4AaABAg.A0HUhGP50htA0INjVlmPv5",
                    "UgzeOw3sNP3c_tdZnrp4AaABAg",
                    "Ugwte8yY07iauXDf7pZ4AaABAg.A-wk-dGjsh6A0CV9-yIRW4"
                ],
                "positive_comment_ids": [
                    "UgxYYZKZ4uoc4ITJ1uN4AaABAg",
                    "UgwKElKYNUWg8eEgYLt4AaABAg.A0HUhGP50htA0SH9ekbfGS",
                    "UgwBqqoi532juJ3q4IB4AaABAg"
                ]
            }
        ],
        "all_neutral_comment_ids": [
            "UgyEnEPeXSTbn2LwDeJ4AaABAg",
            "Ugy5QJysZVxlW9jPnSF4AaABAg",
            "Ugygw-KZcz_j7yFAOvd4AaABAg",
            "Ugz9bsX4OHWqpB5IU7t4AaABAg",
            "UgyoJY0-9dAZzBBHHeV4AaABAg",
            "UgxTcOBk7ZiB25WHEQ94AaABAg",
            "Ugww3oQF9F-xpc8HyV54AaABAg",
            "UgymSBqFDrdbGevBpwh4AaABAg",
            "UgxsKRquP4k04oSinER4AaABAg",
            "UgwSStAdPFgTiRyH9Gx4AaABAg",
            "UgxiRgt-vxZiCfbo-n14AaABAg",
            "UgzfwB8WostJNwLNpCN4AaABAg",
            "Ugztn_1adKQyR-aYHNh4AaABAg",
            "UgwnEFpze2AOCyVq_vt4AaABAg",
            "UgwlqpQkNlWzaiLNOzx4AaABAg",
            "UgyQFBO-GAeqsmhrlgx4AaABAg",
            "UgwJ9HWTvI8L1-u__lt4AaABAg",
            "Ugxvq0eYMAewimYqeRZ4AaABAg",
            "UgzdCCbJmmt_ojHEXS54AaABAg",
            "UgyqsXkwVIcRNCGpPop4AaABAg",
            "UgxsH_uP4W_7LPP9rMd4AaABAg",
            "UgyhBHUxY943VhqCdL94AaABAg.A0O-F8KAtnkA0SH2LTMPSD",
            "Ugw088FErD9dK93ShAp4AaABAg",
            "UgxrDy3hOoNoRLJOT5h4AaABAg",
            "Ugw5juDWugSX1Y_dkFR4AaABAg",
            "UgwKElKYNUWg8eEgYLt4AaABAg",
            "UgwXGIw-cGGNDWelZ_94AaABAg",
            "Ugwzbot8DO64JQCR8mJ4AaABAg",
            "UgwMMEVJXvtM1p0OITx4AaABAg",
            "UgwaiWiORzsptvNMGol4AaABAg",
            "Ugy76nPnDJlX2EmCb3R4AaABAg",
            "UgwKElKYNUWg8eEgYLt4AaABAg.A0HUhGP50htA0INjVlmPv5",
            "UgxbWTHT-NoyV882RKJ4AaABAg.A07FbVdQRBxA0C-Pz0_aJC",
            "UgybrsEJM4FxEWg_grZ4AaABAg",
            "UgwZqvZCVJZ-xWVEqKl4AaABAg",
            "UgxSsGTk_-YIELwWzwd4AaABAg",
            "UgxRnlTR-87thrkISGx4AaABAg",
            "Ugxk0LlF82bkXfBPQkd4AaABAg",
            "Ugyg-thgSfYL6H3ypD94AaABAg",
            "Ugy-IoZr5cGbQSNPFGd4AaABAg",
            "Ugz7TyQ9TNx4bzTNsiR4AaABAg",
            "UgwhR_XjVb4cD9R3MD94AaABAg",
            "Ugyz2SXFw-HWPEIQK514AaABAg",
            "UgxPNpCf0Vh0yQeDmEh4AaABAg",
            "UgyJsfZj22h8_GKrCxp4AaABAg",
            "Ugyg-thgSfYL6H3ypD94AaABAg.A01wEslk6zPA09vslr0t1z",
            "Ugwte8yY07iauXDf7pZ4AaABAg.A-wk-dGjsh6A0AN72X81UW",
            "Ugwte8yY07iauXDf7pZ4AaABAg.A-wk-dGjsh6A0CV9-yIRW4",
            "UgyZs45TyDbvRHoAlfB4AaABAg.A-nazZJlI9JA09w4_pl-Bl",
            "Ugzlr2hcjc7pQD_hTCB4AaABAg",
            "Ugy6lXmK6NC1iXNuEqF4AaABAg",
            "UgxzXqTQ0zgUUsNkztV4AaABAg",
            "Ugy8izftYwv2rKaC8Ph4AaABAg",
            "Ugw5LUcVUkYZ-YzijL94AaABAg",
            "UgzTVZzK8Xbk-b6KBll4AaABAg",
            "UgyLibX2y6f8HUqfukZ4AaABAg",
            "Ugzqf3SHx141bbpKSoN4AaABAg",
            "Ugzo-8UH2PRsTqUmw0J4AaABAg",
            "Ugw2rA55mC-k17t9lLx4AaABAg",
            "UgyR-jZyHp7w9PmvK4N4AaABAg",
            "UgwL0tc1Nn-ie6-U07N4AaABAg.A-ddnAy0SAJA-je2qLPlWA",
            "UgzcMHZ_s718l-jbQ9B4AaABAg.A-dFPT7lkDzA09w7w1uxKK",
            "Ugw5LUcVUkYZ-YzijL94AaABAg.A-bJVF--YcVA09w92aWXTR",
            "UgwfrEslQNBr9atdKF94AaABAg",
            "Ugzb3TICzLCXCyh-btx4AaABAg"
        ],
        "all_negative_comment_ids": [
            "UgxCdoGUFFGiOVs_dqh4AaABAg",
            "UgwkadBntRDvcsm9X2B4AaABAg",
            "UgxKHOPnTK5o23KROuh4AaABAg",
            "UgzFMiJrHLMwmVFYGsJ4AaABAg",
            "UgxrPYNDwhvC0ditEXd4AaABAg",
            "Ugzo3XEEm54PV-pxpBF4AaABAg",
            "Ugzi2HKCGaWKuAYK_5Z4AaABAg",
            "UgwPreVKPhAwYr8PD6h4AaABAg",
            "UgxbWTHT-NoyV882RKJ4AaABAg",
            "Ugzdt2PuRcVhDrPNswd4AaABAg",
            "Ugwte8yY07iauXDf7pZ4AaABAg",
            "UgxRbFL7KQjFELOqv494AaABAg",
            "UgyOgNw0LixSWiiqeXV4AaABAg",
            "Ugwte8yY07iauXDf7pZ4AaABAg.A-wk-dGjsh6A0A0CKKny5Z",
            "UgwL0tc1Nn-ie6-U07N4AaABAg",
            "UgyCzs5keAH78mmwVu54AaABAg",
            "UgzcMHZ_s718l-jbQ9B4AaABAg",
            "Ugx4oWyVY1CA6CcLT0h4AaABAg.A-a_oVq_iXzA09wBC8rdBB",
            "UgzITEl0uFUiGPS9H194AaABAg.A-a7mNZh0UeA09wEG8068D",
            "UgyLibX2y6f8HUqfukZ4AaABAg.A-_xMdiax-bA09wHRsO6ML",
            "Ugzo-8UH2PRsTqUmw0J4AaABAg.A-Xw1QceEFsA09wKU2tSdY",
            "Ugw2rA55mC-k17t9lLx4AaABAg.A-XSvax4TLUA09wMqwEHbY"
        ],
        "all_positive_comment_ids": [
            "UgwQADBferwFP9RpNs14AaABAg",
            "Ugz3XR7bpvZ1Ra3IMy94AaABAg",
            "UgxYYZKZ4uoc4ITJ1uN4AaABAg",
            "Ugz8jho4EIT7eIgfRPp4AaABAg",
            "Ugzj3vUB6g3eYT1q0Rp4AaABAg",
            "Ugzf-RS9-CF0EekXhuh4AaABAg",
            "UgxHL53_3oznKrKcXKB4AaABAg",
            "UgwDQYbkooW8IQa5z4B4AaABAg",
            "Ugwd-o8wcInF1RjS2s94AaABAg",
            "Ugxrs60HodqgOeqc4L14AaABAg",
            "UgzHcDXcCGp_a7NYzPx4AaABAg",
            "UgyW7qpl4OYPrsb2dSV4AaABAg",
            "Ugxmxr1qHHSM-TAci0x4AaABAg",
            "UgwkZbf0crAAQecCudN4AaABAg",
            "Ugw7PLQFR9bMSbjBAqx4AaABAg",
            "UgzP0990k2D7EpQMkLB4AaABAg",
            "UgyhQpTW0TXOG4X5r1B4AaABAg",
            "UgwKElKYNUWg8eEgYLt4AaABAg.A0HUhGP50htA0SH9ekbfGS",
            "UgxbWTHT-NoyV882RKJ4AaABAg.A07FbVdQRBxA0BwgYYO1bY",
            "Ugwp908GSSJ3Z21JSnd4AaABAg",
            "Ugwte8yY07iauXDf7pZ4AaABAg.A-wk-dGjsh6A0D6qQp9VPs",
            "Ugx4oWyVY1CA6CcLT0h4AaABAg",
            "UgzITEl0uFUiGPS9H194AaABAg",
            "UgwBqqoi532juJ3q4IB4AaABAg",
            "Ugwkc8VhkZDVET9pc614AaABAg",
            "Ugy8w8ihbYJ-egybecx4AaABAg"
        ]
    }

    function expired_login_session(storedDateTime) {
        try {
            const storedDate = new Date(storedDateTime);

            const currentDate = new Date();
            // Set expiry date time to 3 hours after the stored date time
            const expiryDate = new Date(storedDate.getTime() + 3 * 60 * 60 * 1000)

            if (expiryDate.getTime() < currentDate.getTime()) {
                return true;
            }

            return false;
        } catch (error) {
            return true;
        }
    }

    const fetchData = async (user_email, user_id, token, storedAt) => {
        if (apiCallInProgress) {
            console.log("API call already in progress...")
            return;
        }

        if (callCount > 3) {
            console.log("Called more thatn 3 times already.")
            return;
        }

        setCallCount(callCount + 1)
        setApiCallInProgress(true);

        const input_data = {
            "app-id": "1ecead0c-d3e1-4bc8-b5a3-757b94f2012f",
            "api-key": "228031c9-a95d-40b2-TempSwift1dot-df659df60e86",
            "video_id": config.video_url_prefix + videoId,
            "video_title": videoTitle,
            "total_comms_on_video": tCommsOnVid,
            "email": user_email,
            "user_id": user_id,
            "auth_token": token,
            "storedAt": storedAt,
            "additional_comments_analysis": additionalCommentsAnalysis
        };

        function getCommsAPIURL() {
            if (config.env === "live") {
                console.log("Using live API")
                return "https://comms-api-1.azurewebsites.net/api/comms_ai_api"
            }
            else if (config.env === "test") {
                console.log("Using AWS test API")
                return "https://lambodhi.pythonanywhere.com/toptopicsdata"
            }
            else {
                console.log("GET COMMS: Using local API")
                return "http://localhost:7071/api/comms_ai_api";
            }
        }

        axios.post(getCommsAPIURL(), input_data, {
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => {
                console.log("Response from API")
                console.log(response.data)
                let responseData = response.data
                while (responseData.hasOwnProperty('data')) {
                    responseData = responseData.data
                }

                setApiCallInProgress(false);
                onAnalysisDone(videoId, responseData)
                return
            })
            .catch(error => {
                console.error('Error fetching data from API:', error);
                setApiCallInProgress(false);
                onAnalysisDone(videoId, Errors.DATA_ERROR);
                return
            });
    };

    const register_user_request = async (user_email, user_id, token, video_id) => {
        const input_data = {
            "app_id": "1ecead0c-d3e1-4bc8-b5a3-757b94f2012f",
            "api_key": "228031c9-a95d-40b2-TempSwift1dot-df659df60e86",
            "email": user_email,
            "user_id": user_id,
            "auth_token": token,
            "video_id": video_id
        };

        api_post(input_data, "register_user_request")
    }

    useEffect(() => {
        try {
            ReactGA.initialize('G-LB80J9P3P9');
            ReactGA.pageview("Data requested for video: " + videoId + " by user: " + JSON.parse(localStorage.getItem('userData')).email)
        }
        catch (error) {
            console.error("Error initializing Google Analytics: ", error)
        }

        if (config.mock_data_on_client) {
            setData(mock_data);
            onAnalysisDone(videoId, mock_data);
            return;
        }
        if (videoId && !apiCallInProgress) {
            const localUserData = JSON.parse(localStorage.getItem('userData'))
            const user_email = localUserData.email
            const user_id = localUserData.user_id
            const storedAt = localUserData.storedAt
            const token = localUserData.token
            if (expired_login_session(storedAt)) {
                localStorage.removeItem('userData')
                window.location.href = "/";
            }

            // console.log("Registering user request...")
            // register_user_request(user_email, user_id, token, videoId)
            fetchData(user_email, user_id, token, storedAt);
        }
        else {
            console.log("Video link not available or API call in progress.", { videoLink: videoId, apiCallInProgress })
        }
    }, []);

    return (
        <></>
    );

}
