import React, { useEffect, useState } from 'react';
import WordCloud from 'react-d3-cloud';

export function WordCloudChart(props) {
  const [data, setData] = useState([]);
  const [max, setMax] = useState(100);

  useEffect(() => {
    const values = props.data.map((r) => r.value);
    console.log('props.data', props.data);
    setMax(Math.max(...values));
    setData(props.data);
  }, [props.data]);

  const fontSize = (word) => (100 * word.value) / max;
  const rotate = () => 0; // word.value % 90;

  if (data.length === 0) {
    return <div>Loading..</div>;
  }

  return (
    <WordCloud
      width={1000}
      height={300}
      data={data}
      fontSize={fontSize}
      fontFamily="cuckoo"    
      rotate={rotate}
      padding={3}
      spiral="rectangular"
      random={Math.random}
    />
  );
}

export default WordCloudChart;
