import '../assets/css/App.css';
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FancyLogin from './FancyLogin';
import { Home } from '@mui/icons-material';


function HomePage({ onLogin }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    console.log('HomePage loaded!');
    handleShow();
  }, []);

  const handleHome = () => {
    window.location.href = '/';
  }


  return (
    <div>
      {show &&
        <Modal show={show} size="lg" centered backdrop="static" keyboard={false}>
          <Modal.Header>
            <Modal.Title>One click sign in</Modal.Title>
            <Button href="https://lambodhi.com" variant="light" onClick={handleHome}>
              <Home />
            </Button>
          </Modal.Header>
          <Modal.Body>
            One click away! Please Sign in using Google Account.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={handleClose} ><FancyLogin onLogin={onLogin} /></Button>
          </Modal.Footer>
        </Modal>
      }
    </div>
  );
}

export default HomePage;
