import React, { useState, useEffect } from 'react';
import YoutubeSearch from './YoutubeSearch';
import AnalysisDashboard from './AnalysisDashboard';
import { api_post } from './UserActivity';
import VideoList from './VideoList';
import AnalysisDataBroker from './AnalysisDataBroker';
import config from '../config.json';


const Pages = {
    SEARCH: 'search',
    ANALYSIS_DASHBOARD: 'analysis_dashboard'
}

const HomeTab = ({ increment_analysis_count }) => {
    const [urlVideoResult, setUrlVideoResult] = useState('');
    const [results, setResults] = useState('');
    const [analysingList, setAnalysingList] = useState([]);
    const [analysedDataList, setAnalysedDataList] = useState([]);
    const [showPage, setShowPage] = useState('search')
    const [fetchDataVideoId, setFetchDataVideoId] = useState('');
    const [fetchDataVideoTitle, setFetchDataVideoTitle] = useState('');
    const [tCommsOnVid, setTCommsOnVid] = useState('');

    const [searchPage, setSearchPage] = useState('')
    const [currentDashboardItem, setCurrentDashboardItem] = useState('');
    const [currentDashboardData, setCurrentDashboardData] = useState('');

    function send_user_activity_to_server(id) {
        const input_data = {
            "app_id": "1ecead0c-d3e1-4bc8-b5a3-757b94f2012f",
            "api_key": "228031c9-a95d-40b2-TempSwift1dot-df659df60e86",
            "email": "user_email",
            "user_id": "user_id",
            "token": "token",
            "video_id": id
        }
        api_post(input_data, "register_user_request")
    }

    function onUrlInputSubmit() {
        setSearchPage('url');
    }

    function onReturnToSearch() {
        setShowPage('search');
    }

    function onTextInputSubmit() {
        setSearchPage('text');
    }

    useEffect(() => {
        console.log('Home page tab ...');
    }, [analysedDataList]);

    useEffect(() => {
        console.log("On home page load...");
    }, []);

    function onAnalysisDone(id, data) {
        console.log('**************** Analysis Done', id, data);
        setAnalysingList(analysingList.filter(item => item !== id));
        const updated_list = analysedDataList.filter(itemData => itemData.key !== id);
        setAnalysedDataList([...updated_list, { key: id, data: data }]);
        console.log('**************** Updated analysedDataList', analysedDataList);
        setFetchDataVideoId('')
        setFetchDataVideoTitle('')
        setTCommsOnVid('')
    }

    function onActionClick(videoToAnalyse, currentStatus) {
        if (currentStatus === 'analyse') {
            if (analysedDataList.some(itemData => itemData.key === videoToAnalyse.id.videoId)) {
                setCurrentDashboardItem({ id: videoToAnalyse.id.videoId, title: videoToAnalyse.snippet.title, statistics: videoToAnalyse.statistics });
                setCurrentDashboardData(analysedDataList.find(itemData => itemData.key === videoToAnalyse.id.videoId).data);
                setFetchDataVideoTitle(videoToAnalyse.snippet.title);
                setShowPage(Pages.ANALYSIS_DASHBOARD);
                return
            }
            if (!(analysingList.some(item => item === videoToAnalyse.id.videoId))) {
                setAnalysingList([...analysingList, videoToAnalyse.id.videoId]);
                setFetchDataVideoId(videoToAnalyse.id.videoId);
                setFetchDataVideoTitle(videoToAnalyse.snippet.title);
                setTCommsOnVid(videoToAnalyse.statistics.commentCount ? videoToAnalyse.statistics.commentCount : 0)
                return
            }
        }
        if (currentStatus === 'view') {
            setCurrentDashboardItem({ id: videoToAnalyse.id.videoId, title: videoToAnalyse.snippet.title, statistics: videoToAnalyse.statistics });
            setCurrentDashboardData(analysedDataList.find(itemData => itemData.key === videoToAnalyse.id.videoId).data);
            setFetchDataVideoTitle(videoToAnalyse.snippet.title);
            setShowPage(Pages.ANALYSIS_DASHBOARD);
            return
        }
        return
    }

    function onSearchResults(textSearchResults) {
        if (searchPage === '') {
            setSearchPage('text');
        }
        setResults(textSearchResults);
    }

    function onUrlResult(urlResult) {
        setUrlVideoResult(urlResult)
    }

    return (
        <div>
            <h6 className='m_container' style={{ marginBottom: "16px", alignItems: "center" }}>Home</h6>
            {showPage === Pages.SEARCH &&
                (
                    <div>
                        <YoutubeSearch searchText={''} onUrlSubmit={onUrlInputSubmit} onTextSubmit={onTextInputSubmit} onSearchResults={onSearchResults} onUrlResult={onUrlResult} previousResults={results !== ''} />
                        {searchPage === 'text' && results !== '' &&
                            <VideoList results={results} onActionClick={onActionClick} analysingList={analysingList} analysedList={analysedDataList.map(itemData => itemData.key)} />
                        }
                        {
                            fetchDataVideoId !== '' && <AnalysisDataBroker videoId={fetchDataVideoId} videoTitle={fetchDataVideoTitle} tCommsOnVid={tCommsOnVid} onAnalysisDone={onAnalysisDone} additionalCommentsAnalysis={false} />
                        }
                        {
                            searchPage === 'url' && urlVideoResult !== '' &&
                            <VideoList results={[{ id: { videoId: urlVideoResult.id }, snippet: { title: urlVideoResult.snippet.title }, statistics: urlVideoResult.statistics }]} onActionClick={onActionClick} analysingList={analysingList} analysedList={analysedDataList.map(itemData => itemData.key)} />
                        }
                    </div>
                )
            }
            {showPage === Pages.ANALYSIS_DASHBOARD &&
                (<div>
                    <AnalysisDashboard videoInfo={currentDashboardItem} analysisData={currentDashboardData} onReturnToSearch={onReturnToSearch} tCommsOnVid={tCommsOnVid} onAnalysisDone={onAnalysisDone} type="initial" />
                </div>)
            }
        </div >
    );
}

export default HomeTab;
