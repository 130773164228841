import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Assuming you're using axios for making HTTP requests
import config from '../config.json';

const UserRequest = () => {
  const [userList, setUserList] = useState([]);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  function expired_login_session(storedDateTime) {
    try {
      const storedDate = new Date(storedDateTime);

      const currentDate = new Date();
      // Set expiry date time to 3 hours after the stored date time
      const expiryDate = new Date(storedDate.getTime() + 3 * 60 * 60 * 1000)

      if (expiryDate.getTime() < currentDate.getTime()) {
        return true;
      }

      return false;
    } catch (error) {
      return true;
    }
  }

  function get_user_data() {
    const localUserData = JSON.parse(localStorage.getItem('userData'))
    console.log("Local user data: ", localUserData)
    const user_email = localUserData.email
    const user_id = localUserData.user_id
    const storedAt = localUserData.storedAt
    if (expired_login_session(storedAt)) {
      localStorage.removeItem('userData')
      window.location.href = "/";
    }

    return { user_email, user_id }
  }

  useEffect(() => {
    // Function to fetch user list
    const fetchUserList = async () => {
      try {
        // Fetching the user's credentials from wherever they are stored
        const user_email = get_user_data().user_email
        console.log("2Fetching user requests")

        const apiKey = '601c5d17-e9d8-umesh4192-91db-60nh14ffdadsfd8a101a';
        const count = '3842'
        let url = ''
        if (config.env === 'live') {
          console.log("Live server")
          url = 'https://comms-api-1.azurewebsites.net/api/supadminasdf789adfkajsif'
        }
        else {
          console.log("Local host")
          url = 'http://localhost:7071/api/supadminasdf789adfkajsif'
        }

        const current_place = window.location.href
        console.log("Current place: ", current_place)
        console.log("Url ", url)
        // Making the API call
        const response = await axios.post(
          url,
          {
            user_email: user_email,
            api_key: apiKey,
            count: count,
            current_place: current_place
          }
        );

        // Handling the response
        console.log("Received Response: ", response)
        setUserList(response.data);
      } catch (error) {
        setError(error.message);
      }
    };

    // Call the function to fetch the user list
    fetchUserList();
  }, []); // Dependency array to ensure the effect runs only once

  return (
    <div>
      <h1>Request List</h1>
      {error && <p>Error: {error}</p>}
      <ul>
        {userList.map((user, index) => (
          <li key={index}>{user}</li>
        ))}
      </ul>
    </div>
  );
};

export default UserRequest;
