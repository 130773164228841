import React from "react";
import UserList from "./UserRequest"

export default function AdminPage() {

  return (
    <div>
      <h5 style={{ alignItems: "center" }}>Admin Page</h5>
      <UserList />
    </div>
  );
}