import React, { useState, useEffect } from 'react';

import LinearProgress from '@mui/material/LinearProgress';

export default function AnalysisLoading() {
  const [loadingText, setLoadingText] = useState('Loading ...');

  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => { });
  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 2700);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    displayText();
  }, []);

  const displayText = () => {

    setLoadingText("Initializing analysis ...");
    const messages = [
      "Getting comments from the video ...",
      "Mapping the comments ...",
      "Sorting comments ...",
      "Extracting top comments ...",
      "Preparing comments for analysis ...",
      "Analysing sentiment ...",
      "Detecting sentiment patterns ...",
      "Investigating user feedback ...",
      "Assessing audience sentiment ...",
      "Parsing comment metadata ...",
      "Examining comment engagement ...",
      "Assessing comment relevance ...",
      "Reviewing comment sentiment ...",
      "Processing comment timestamps ...",
      "Summarizing comment sentiment ...",
      "Evaluating comment sentiment ...",
      "Parsing comment sentiment data ...",
      "Compiling sentiment analysis ...",
      "Analyzing sentiment trends ...",
      "Reviewing comment sentiment patterns ...",
      "Analyzing comment sentiment trends ...",
      "Interpreting comment sentiment data ...",
      "Assessing comment sentiment patterns ...",
      "Identifying sentiment trends ...",
      "Reviewing comment engagement metrics ...",
      "Assessing comment engagement patterns ...",
      "Analyzing comment engagement data ...",
      "Examining comment engagement metrics ...",
      "Summarizing comment engagement trends ...",
      "Mapping data ...",
      "Handing data to UI component ...",
      "Rendering data in UI component ...",
      "Fetching second batch, this should take couple more seconds ...",
      "Starting next batch ...",
    ];

    let currentIndex = 0;
    const interval = setInterval(() => {

      setLoadingText(messages[currentIndex]);
      currentIndex++;

      // Reset index if all messages have been displayed
      if (currentIndex === messages.length) {
        currentIndex = 0;
      }
    }, 3100);

    // Clear interval when component unmounts or when apiCallInProgress becomes false
    return () => clearInterval(interval)
  }

  return (
    <>
      {loadingText && < div > {<span style={{ color: "blue", fontSize: "12px" }}>{loadingText}</span>} <LinearProgress color="secondary" variant="buffer" value={progress} valueBuffer={buffer} /></div >}
    </>
  )
}