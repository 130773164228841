
import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import { Accordion, AccordionHeader, Col, Container, Row } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import StackedProgressBar from './ProgressBar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CommentDrawer from './CommentsDrawer';
import CommentsBroker from './CommentsBroker';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Badge from '@mui/material/Badge';


export default function TileCard({ title, total, positives, negatives, neutrals, summary, positiveComments, negativeComments, neutralComments, likes, video_id }) {
  if (!positiveComments) positiveComments = [];
  if (!negativeComments) negativeComments = [];
  if (!neutralComments) neutralComments = [];
  const [openComments, setOpenComments] = useState(false);
  const [commentsType, setCommentsType] = useState('');
  const [fetchComments, setFetchComments] = useState(false);
  const [fetchedTitleComms, setFetchedTitleComms] = useState([]);
  const [positiveCommentsText, setPositiveCommentsText] = useState([]);
  const [negativeCommentsText, setNegativeCommentsText] = useState([]);
  const [neutralCommentsText, setNeutralCommentsText] = useState([]);

  const handleOpenComments = () => setOpenComments(true);
  const handleCloseComments = () => setOpenComments(false);

  function onCommentsReceived(commentsData) {
    console.log('DEBUG: Found comments');
    const po_comments = [];
    const neg_comments = [];
    const nu_comments = [];
    const fe_comments = [];
    commentsData.forEach(item => {
      const [key, value] = Object.entries(item)[0];

      fe_comments.push(key);

      if (positiveComments.includes(key)) {
        console.log('Positive comment', key, value);
        po_comments.push({ "id": key, "comment": value });
      }
      if (negativeComments.includes(key)) {
        console.log('Negative comment', key, value);
        neg_comments.push({ "id": key, "comment": value });
      }
      if (neutralComments.includes(key)) {
        console.log('Neutral comment', key, value);
        nu_comments.push({ "id": key, "comment": value });
      }
    });
    setPositiveCommentsText(po_comments);
    setNegativeCommentsText(neg_comments);
    setNeutralCommentsText(nu_comments);
    setFetchedTitleComms([title, ...fetchedTitleComms]);
  }

  function viewPositiveComments() {
    viewComments('positive');
  }

  function viewNegativeComments() {
    viewComments('negative')
  }

  function viewNeutralComments() {
    viewComments('neutral')
  }

  function getLocallyStoredComments(videoId) {
    let storedComments = JSON.parse(localStorage.getItem('comments')) || {};
    return storedComments[videoId] || [];
  }

  function viewComments(type) {
    console.log('View ' + type + 'Comments clicked');
    setCommentsType(type);
    handleOpenComments();

    const locallyStoredComms = getLocallyStoredComments(video_id);
    console.log('DEBUG Locally stored comments', locallyStoredComms);
    if (locallyStoredComms && locallyStoredComms.length > 0) {
      setFetchComments(false);
      onCommentsReceived(locallyStoredComms);
    }
    else {
      console.log('DEBUG Fetching comments from server.', video_id);
      setFetchComments(true)
    }
  }

  return (
    <>
      <Card className='smallcard' style={{ borderRadius: '30px', borderTop: 'none', borderLeft: 'none', borderRight: 'none', background: '#fffff' }}>
        <Card.Body>
          <Container>
            <Row>
              <Accordion defaultActiveKey="0">
                <AccordionHeader>
                  <Col xs={2} style={{ padding: '1px' }}>
                    <Card.Text title="Total" style={{ width: '75px', padding: '2px', lineHeight: '1', background: 'rgb(244, 247, 254)', borderRadius: '10px', margin: '0px', color: 'blue', fontWeight: 'bold', fontSize: '18px' }} className='smallcardvalue'>{total}<div className='normalText'> comments</div></Card.Text>
                  </Col>
                  <Col xs={10} style={{ padding: '2px', paddingLeft: '30px', textAlign: 'left' }}>
                    <Card.Title className='smallcardlabel'>{title}</Card.Title>
                  </Col>
                </AccordionHeader>
                <Accordion.Body>
                  <Stack direction="column" spacing={2} style={{ marginBottom: "20px" }}>
                    <Chip onClick={viewPositiveComments} label={positives + " positive comments"} color="success" variant="outlined" />
                    <Chip onClick={viewNegativeComments} label={negatives + " negative comments"} color="secondary" variant="outlined" />
                    <Chip onClick={viewNeutralComments} label={neutrals + " neutral comments"} color="primary" variant="outlined" />
                  </Stack>
                  <h6>Summary of comments: </h6>
                  {summary}
                </Accordion.Body>
              </Accordion>
            </Row>
            <Row style={{ marginTop: '2%' }}>
              <StackedProgressBar total={total} positive={positives} negative={negatives} neutral={neutrals} />
            </Row>
          </Container>
        </Card.Body>
      </Card>
      <>
        {!fetchedTitleComms.includes(title) && fetchComments && title.toLowerCase() !== 'all other topics' && <CommentsBroker video_id={video_id} comment_ids={[]} onResult={onCommentsReceived} />}
        <CommentDrawer commentsType={commentsType} open={openComments} handleClose={handleCloseComments} positiveCommentsText={positiveCommentsText} negativeCommentsText={negativeCommentsText} neutralCommentsText={neutralCommentsText} loading={!fetchedTitleComms.includes(title)} />
      </>
    </>
  );
};